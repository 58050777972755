/**
 * ==================== @ BLOCS
*/

.block-accordion {

  .tabs {
		overflow: hidden;
	}
	
	.tab {
		border-bottom: 1px solid rgba(black,.1);
		overflow: hidden;
		width: 100%;
		
		&-label {
			display: block;
			font-family: $font-alt;
			font-size: rem(28);
			cursor: pointer;
			padding: 1rem 3rem 1rem 1rem;
			position: relative;
			transition: color .35s;

			&::before, &::after {
				content: '';
				height: 2px;
				width: 18px;
				background-color: $color2;
				position: absolute;
				top: 36px;
				right: 0;
				transition: transform 550ms;
			}

			&::after {
				transform: rotate(-90deg);
			}
		}
		
		&-content {
			background-color: var(--beige);
			font-size: rem(16);
			max-height: 0;
			padding: 0 1rem;
			transition: all .35s;
			
			p {
				margin-bottom: 0;
			}
		}
		
		&.active {
			 .tab-label {
				&::after {
					transform: rotate(180deg);
				}
			}
			
			.tab-content {
				max-height: 75vh;
				padding: 1rem;
			}
		}
	}
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {

  .block-accordion {

    .tab {

      &.active {
        .tab-content {
          max-height: 36vh;
          padding: 1rem;
        }
      }
    }
  }
}