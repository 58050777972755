/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/

.card-audio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: .75rem;
  padding: .5rem;

  &__img {
    flex: 0 0 25%;
    min-width: 100px;
    order: 1;

    img {
      display: block;
    }
  }

  &__desc {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    order: -1;

    p {
      font-weight: 500;
    }

    div {
      display: flex;
      align-items: baseline;
      gap: 1rem;

      p {
        font-family: $font-alt;
        font-size: rem(16);
        margin-top: 0;
      }

      .svg-icon {
        color: $color9;
        font-size: rem(24);
      }
    }
  }

  &__length {
    order: 2;
    text-align: right;

    span {
      color: $color9;
      font-size: rem(15);
      font-weight: 300;
    }
  }

  &__play {
    order: 3;
  }

	&.playing, &:hover {
		background-color: rgba(0,0,0,.05);
		box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.03);
	}
}

@media all and (min-width : $bp-m) {
  
  .card-audio {
    gap: .75rem;

    &__img {
      flex: 0 0 15%;
    }

    &__desc {
      order: 2;
      flex: 1;
  
      div {
  
        p {
          font-size: rem(20);
        }
      }
    }
  }
}