/**
 * ==================== @ GRID 3 / 4 SLIDES
*/

.grid-moocs {
  background-color: $color12;
  border-radius: var(--radius-8);
	display: grid;
  align-items: stretch;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 1rem;
}


/* === 1024px === */
@media screen and (min-width:$bp-l) {

  .grid-moocs {
    grid-template-columns: repeat(2, 1fr);
  }
}