/**
 * ==================== @ HEADER
 */

.header {
	background-color: #000;
	padding-top: 14px;
	padding-bottom: 14px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99;

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__logo {
	}

	&__nav {
	}
	
	&__lang {
		display: none;
	}
	
	&__menu {

		&-burger {
			border-radius: 50%;
			border: 1px solid #fff;
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.svg-icon {
			color: #fff;
		}
	}
}

@media all and (max-width : 1023px) {
	.header {

		&__nav {
			background-color: rgba(0,0,0,.6);
			backdrop-filter: blur(10px);
			position: fixed;
			left: 0;
			top: 64px;
			bottom: 0;
			width: 100vw;
			transform: translateX(-100%);
			transition: transform 350ms 50ms;

			.is-nav-open  & {
				transform: translateX(0);
			}
		}
	}
}

@media all and (min-width : 40em) {
	
  .header-accessibility {
    padding-right: 1.5rem;
  }
}

@media all and (min-width : $bp-l) {
	
	.header {
		min-height: 65px;

		&__menu {
			display: none;
		}

		.glightbox-open & {
			margin-right: 17px;
		}

    .is-glossy & {
      background-color: rgba(0,0,0,.7);
      backdrop-filter: blur(10px);
    }
	}
}

@media all and (min-width : $bp-1280) {

	.header {
		
		&__lang {
			display: block;
		}
	}
}
