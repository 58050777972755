/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/

.card-video {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: .5rem;
  padding: 1rem;
  transition: background-color 250ms;
  $parent: &;

  &__icn, &__play {
    flex: 0 0 25px;
  }
  
  &__play {
    align-self: last baseline;
    text-align: center;
  }

  &__metas {
    color: #000;
    flex: 1;
    transition: color 250ms;
  }

  &__title {
    color: inherit;
    font-family: $font-alt;
    font-size: rem(14);
    word-wrap: break-word;
    word-break: break-word;
  }

  &:hover, &.playing {
    background-color: rgba(0,0,0,.08);
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.03);

    #{$parent}__metas {
      color: #fff;
    }
  }
}

@media all and (min-width : $bp-m) {

  .card-video {

    &__title {
      font-size: rem(20);
    }
  }
}