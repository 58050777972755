/**
 * ==================== @ FOOTER
 */

.footer {
  margin: 1.5rem 0;

  .padded-edges {
    border-top: 1px solid rgba(#707070, .1);
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__logo {
    
    img {
      margin: auto;
    }
  }

  &__nav {
    display: flex;

    &-rs {
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    &-legal {
      flex-direction: column;
      gap: .5rem;
      
      ul {
        display: flex;
        flex-direction: column;
        gap: .5rem;
      }
  
      .wave {
        font-size: rem(28);
      }
    }
  }
}

@media screen and (min-width: $bp-l) {

  .footer {

    &__nav {
      justify-content: space-between;
      align-items: baseline;

      &-rs {
        justify-content: flex-end;
      }

      &-legal {
        flex-direction: row;
      
        ul {
          flex-direction: row;
        }
      }
    }
  }
}