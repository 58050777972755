/**
 * ==================== @ ICONS
 */

.svg-icon {
	display: inline-block;
	fill: currentColor;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
	width: 1em;
}

.icon-rs {
	background-color: rgba($color13, .2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 32px;

	.svg-icon {
		transition: color 150ms;
	}

	&:hover {

		.svg-icon {
			color: $color11;
		}
	}
}

.leaf {
	font-size: rem(128);
	color: var(--theme-color);

	&.them-campus {
		color: var(--theme-color-alt);
	}
}

.icon-video {
	border: 1px solid #fff;
	border-radius: 4px;
	color: #fff;
	display: block;
	line-height: 8px;
	height: 18px;
	width: 24px;
	text-align: center;

	.svg-icon {
		font-size: rem(10);
	}
}

.icon-playpause {
	border-radius: 50%;
	background-color: $color9;
	cursor: pointer;
	width: 32px;
	height: 32px;
	position: relative;
	transition: transform 150ms;

	&:hover {
		transform: scale3d(1.1,1.1,1);
	}

	&::before {
		content: '';
		display: block;
		content: "";
		height: 100%;
		position: absolute;
		left: calc(50% - 4px);
		top: calc(50% - 8px);
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 11px solid #fff;
	}

	.playing & {
		&:before {
			width: 24px;
			height: 16px;
			left: 10px;
			border-style: double;
			border-width: 0 0 0 12px;
			border-color: #fff;
		}
	}
}

.explore {
	font-size: rem(128);
	height: 1rem;

	&.ocre {
		color: $color13;
	}

	&.white {
		color: #fff;
	}
}

.theme-icon {
	font-size: rem(28);
}

@media screen and (min-width: $bp-l) {
}