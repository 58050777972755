/**
 * ==================== @ BLOC LIST VIDEOS YT
*/

.block-videos {
  --scrollheight: 56vw;

  &__media {

    .video-wrapper {
      background-color: #000;
      height: var(--scrollheight);
      overflow: hidden;

      iframe {
        height: 100%;
        width: 100%;
      }

      .video-embed {
        display: none;
      }

      &.playing {
        figure {
          display: none;
        }
        .video-embed {
          display: block;
        }
      }
    }
  }

  &__list {
    background-color: $color11;
    height: var(--scrollheight);
    padding: 1rem;
  }
}

@media all and (min-width : $bp-m) {

  .block-videos {
    --scrollheight: 31vw;

    &__items {
      padding-left: .75rem;
    }

    &__media {
      padding-right: .75rem;
    }

    &__list {
      padding: 2rem;
    }
  }
}

@media all and (min-width : $bp-l) {

}
