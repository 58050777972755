/**
 * ==================== @ BLOC LAYOUT RESSOURCE
*/

.block-ressource {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  counter-reset: cnt;

  &.m--head {
    background-color: #000;
    border-radius: var(--radius-8);
    color: #fff;
    margin-top: -4rem;
    padding: 1rem;
    z-index: 2;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    padding: .5rem;

    label {
      font-size: rem(18);
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__documents {
    padding: 1rem;
  }
  
  &__steps {
    order: -1;
  }

}

@media all and (min-width : $bp-m) {

  .block-ressource {
    gap: 0;
    margin-top: -2rem;
    position: relative;
    //padding-top: 3rem;

    &.m--head {
      display: flex;
      flex-direction: row;

      > div {
        flex: 0 0 50%;
      }
    }

    > div {
      padding: 1rem 2rem;
    }

    &--edito {
      margin-top: 0;
      margin-bottom: 3rem;
      padding-top: 0;
      padding-bottom: 1rem;

      .data-last {
        padding-left: 0;
      }
    }
  }
}