/**
 * ==================== @ ELEMENT CARD STYLED
*/

.card-styled {

  figure {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      background-color: var(--theme-color-alt, 'white');
      border-radius: 16px 16px 0 0;
      height: 40px;
      position: absolute;
      left: -2px;
      right: -2px;
      bottom: -6px;
      transform: rotate(4deg);
      transform-origin: 0 100%;
      outline: 1px solid transparent;
      -webkit-transform-style: preserve-3d;
    }
  }

  picture {
    will-change: transform;
    transition: transform 500ms ease-out;
  }

  &__title {
    position: relative;
    margin-top: -.5rem;
    padding: 0 .5rem;
  }

  &__caption {
    padding: 0 .5rem;

    p {
      font-size: rem(16);
      font-weight: 500;
    }
  }
}

@media all and (min-width : $bp-m) {

  .card-styled {

    &:hover {

      picture {
        transform: scale3d(1.1,1.1,1);
      }
    }
  }
}
