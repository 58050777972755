/**
 * ==================== @ GENERIC / PAGES
 */

body:not(.home) .page-header {
	align-items: last baseline;
	//margin-bottom: 2rem;
	padding-bottom: 1rem;
	position: relative;
	overflow: hidden;

	&.has-hero {
		padding-bottom: 10rem;
	}

	&__shape {
		background-color: var(--theme-color);
		padding-left: 50%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		text-align: right;
		z-index: -1;

		#shape-header {
			path { fill: var(--theme-color-shape)}
		}
	}

	h2.h3-like {
		text-transform: uppercase;
	}

	&__title {
    font-weight: 700 !important;
		text-transform: uppercase;
    -moz-osx-font-smoothing: grayscale;
	}

	&__subtitle {
		font-size: rem(17);
		font-weight: 500;
	}

	&__metas {
		display: flex;
		align-items: center;
		gap: 2rem 1rem;

		.metas__date {
			font-size: rem(12);
			margin-left: auto;
		}
	}
}

.page-summary {
	background-color: #fff;
  margin-top: 0;
	position: sticky;
	top: 65px;
	z-index: 10;

	.grid-data {
		border-bottom: 1px solid rgba(black,.1);
		display: flex;
		flex-wrap: wrap;
		gap: .5rem 2rem;
		justify-content: center;
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	
		a {
      display: block;
			font-weight: 500;

      &::after {
        content: '';
        background-image: url('data:image/svg+xml,\00003csvg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">\00003cpath d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">\00003c/path>\00003c/svg>');
        display: inline-block;
        margin-bottom: -7px;
        height: 24px;
        width: 24px;
      }
		}
	}

  &.subpages {
    a::after {
      transform: rotate(-90deg);
    }
  }
}

/**
 * ==================== @ HOME
 */

.home {
	.page-header {
		position: relative;

		figure img {
			object-fit: cover;
			height: calc(100vh - 60px);

		}

		.caption {
			position: absolute;
			left: 1rem;
			top: 58%;
			transform: translateY(-50%);
			z-index: 2;

			h1 {
				color: #fff;
				font-family: $font-stack;
				font-size: rem(40);
				font-weight: 500;
				text-transform: uppercase;

        &.fontsize--4{font-size: rem(24);}
        &.fontsize--3{font-size: rem(24);}
        &.fontsize--2{font-size: rem(30);}
        &.fontsize--1{font-size: rem(35);}
        &.fontsize-1{font-size: rem(45);}
        &.fontsize-2{font-size: rem(50);}
			}
		}
	}

  .l-main-content {
    margin-top: 0;
  }
}

/**
 * ==================== @ ALL PAGES
 */

/* === 640px === */
@media screen and (min-width:$bp-s) {

	.home {
		.page-header {
	
			.caption {
				left: 2rem;

				h1 {
					font-size: rem(48);
				}
			}
		}
	}
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {

	.home {
		.page-header {
	
			.caption {
				left: 4rem;
				
				h1 {
					font-size: rem(56);
				}
			}
		}
	}

	body:not(.home) .page-header {
		// margin-bottom: 2rem;

		&__shape {
			padding-left: 0;
		}

		.page-header__title {
			font-size: em(46);
			padding-right: 3.5rem;
		}
	}

	body.ressource .page-header {
		padding-bottom: 4rem;
	}
}

/* === 1280px === */
@media screen and (min-width:$bp-1280) {

	.home {
		.page-header {
	
			.caption {
				left: 4rem;
				
				h1 {
					font-size: rem(96);
          &.fontsize--4{font-size: rem(64);}
          &.fontsize--3{font-size: rem(72);}
          &.fontsize--2{font-size: rem(80);}
          &.fontsize--1{font-size: rem(88);}
          &.fontsize-1{font-size: rem(104);}
          &.fontsize-2{font-size: rem(112);}
				}
			}
		}
	}
}
