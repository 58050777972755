@charset "UTF-8";
/**
 * Use BEM : https://en.bem.info/
 */
/**
 * ==================== @ THEME VARIABLES
 */
:root {
  --gutter: 1rem;
  --margin-layout: 1rem;
  --col-width: 113px;
  --radius-6: 3px;
  --radius-8: 4px;
  --radius-16: 8px;
}

@media screen and (min-width: 64em) {
  :root {
    --radius-6: 6px;
    --radius-8: 8px;
    --radius-16: 1rem;
  }
}
/*
$svg-pen: '<svg viewBox="0 0 38 32"><path d="M1.69 1.7A5.61 5.61 0 0 1 5.07.04h.02a4.03 4.03 0 0 1 3.33 1.11l21 21c.1.1.17.22.2.34l2.27 8.53a.75.75 0 0 1-.72.97.74.74 0 0 1-.2 0l-8.52-2.3a.68.68 0 0 1-.34-.2L1.14 8.43a4.8 4.8 0 0 1 .54-6.73zm25.13 24.07a5.69 5.69 0 0 0 1.27-2.76v-.04L8.34 3.22A7.8 7.8 0 0 1 6.9 5.85v-.01zm2-.32a8.52 8.52 0 0 1-3.38 3.4l-.05.03 4.7 1.27zm-5.9 2.67a5.66 5.66 0 0 0 2.81-1.28L5.81 6.94a7.76 7.76 0 0 1-2.56 1.43l-.06.02zM2 7.1a6.58 6.58 0 0 0 5.07-5.04L7.08 2a2.6 2.6 0 0 0-1.86-.47h.01c-.98.11-1.84.56-2.48 1.23A3.41 3.41 0 0 0 2 7.1z"></path></svg>';
*/
/**
 * ==================== @ SASS FUNCTIONS
 */
/**
 * ==================== @ SASS MIXINS
 */
/**
 * ==================== @ SASS PLACEHOLDERS
 */
.l-clearfix::before, .l-clearfix::after {
  content: " ";
  display: table;
}
.l-clearfix::after {
  clear: both;
}

/**
 * ==================== @ BASE STYLES
 */
@font-face {
  font-family: "neue";
  src: url("/assets/fonts/NeueHaasUnica-Light.woff2") format("woff2"), url("/assets/fonts/NeueHaasUnica-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "neue";
  src: url("/assets/fonts/NeueHaasUnica-LightItalic.woff2") format("woff2"), url("/assets/fonts/NeueHaasUnica-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "neue";
  src: url("/assets/fonts/NeueHaasUnica-Medium.woff2") format("woff2"), url("/assets/fonts/NeueHaasUnica-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "neue";
  src: url("/assets/fonts/NeueHaasUnica-MediumItalic.woff2") format("woff2"), url("/assets/fonts/NeueHaasUnica-MediumItalic.woff2") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "neue";
  src: url("/assets/fonts/NeueHaasUnica-Regular.woff2") format("woff2"), url("/assets/fonts/NeueHaasUnica-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "clearfaceStd";
  src: url("/assets/fonts/ClearfaceStd-Regular.woff2") format("woff2"), url("/assets/fonts/ClearfaceStd-Regular.woff2") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #fff;
  color: #000;
  font: 1rem "neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.5px;
  padding: 0;
  margin: 0;
  position: relative;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-font-smoothing: antialiased;
}
body.is-nav-open {
  height: 100vh;
  overflow: hidden;
}

a {
  color: #000;
  text-decoration: none;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

strong {
  font-size: inherit;
  font-weight: 500;
  margin-left: 0;
}

figure {
  display: block;
  margin: 0;
  overflow: hidden;
  position: relative;
}
figure img {
  display: block;
  width: 100%;
}
figure picture {
  display: block;
}
figure figcaption {
  font-size: 0.75rem;
  font-weight: 300;
  padding-bottom: 1rem;
}

iframe {
  border: 0;
}

img {
  height: auto;
  max-width: 100%;
  display: inline-block;
}

table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
select {
  border-radius: 0;
}

.sv-g [class*=sv-u] {
  font-family: "neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1px;
}

@media screen and (min-width: 64em) {
  body.is-nav-open {
    height: auto;
    overflow: visible;
  }
  body.is-nav-open::before {
    content: none;
  }
}
/**
 * ==================== @ GENERIC FLOW TOOLS
 */
/*.l-wrapper {
	letter-spacing: .1px;
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 80em;
	margin: 0 auto;
  position: relative;
	z-index: 1;
}*/
.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
  text-align: center;
}

.l-right {
  text-align: right;
}

.py-1-0 {
  padding-top: 1rem;
}

.py-2-0 {
  padding-top: 2rem;
}

.py-3-0 {
  padding-top: 3rem;
}

.py-4-0 {
  padding-top: 4rem;
}

.my-2-0 {
  margin-top: 2rem;
}

.my-3-0 {
  margin-top: 3rem;
}

.py-0-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.my-0-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.img-block {
  display: block;
}

/* radius layout
------------------------*/
.rounded {
  border-radius: var(--radius-16);
}
.rounded-6 {
  border-radius: var(--radius-6);
}
.rounded-8 {
  border-radius: var(--radius-8);
}

/* EMBED
----------------------------------*/
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;
  overflow: hidden;
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
  min-height: 400px;
}

.l-main {
  margin-top: 45px;
}
.l-main-content {
  overflow: hidden;
  margin-top: 1rem;
}
.them-campus .l-main-content {
  margin-top: 2rem;
}

#contact-form {
  scroll-margin-top: 150px;
}

.l-scrollbar {
  position: relative;
}

/* Backgrounds
------------------------*/
.bg-grey {
  background-color: #2B4536;
  color: #504A40;
}
.bg-grey p, .bg-grey h2, .bg-grey h3 {
  color: #504A40;
}

.c-green {
  color: #8C712B;
}

@media screen and (max-width: 767px) {
  .l--desktop {
    display: none;
  }
}
@media screen and (min-width: 64em) {
  .l--mobile {
    display: none;
  }
  .padded-edges {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .l-main {
    margin-top: 65px;
  }
  .l-main-content {
    margin-top: 2rem;
  }
  /* radius layout
  ------------------------*/
  .half-rounded {
    border-radius: 0 var(--radius-24) 0 var(--radius-24);
  }
}
/**
 * ==================== @ ICONS
 */
.svg-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  vertical-align: -0.2em;
  width: 1em;
}

.icon-rs {
  background-color: rgba(199, 185, 157, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}
.icon-rs .svg-icon {
  transition: color 150ms;
}
.icon-rs:hover .svg-icon {
  color: #4C94B2;
}

.leaf {
  font-size: 8rem;
  color: var(--theme-color);
}
.leaf.them-campus {
  color: var(--theme-color-alt);
}

.icon-video {
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  display: block;
  line-height: 8px;
  height: 18px;
  width: 24px;
  text-align: center;
}
.icon-video .svg-icon {
  font-size: 0.625rem;
}

.icon-playpause {
  border-radius: 50%;
  background-color: #3B7E9B;
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: relative;
  transition: transform 150ms;
}
.icon-playpause:hover {
  transform: scale3d(1.1, 1.1, 1);
}
.icon-playpause::before {
  content: "";
  display: block;
  content: "";
  height: 100%;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 8px);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 11px solid #fff;
}
.playing .icon-playpause:before {
  width: 24px;
  height: 16px;
  left: 10px;
  border-style: double;
  border-width: 0 0 0 12px;
  border-color: #fff;
}

.explore {
  font-size: 8rem;
  height: 1rem;
}
.explore.ocre {
  color: #C7B99D;
}
.explore.white {
  color: #fff;
}

.theme-icon {
  font-size: 1.75rem;
}

/**
 * ==================== @ GLOBAL LAYOUT
 */
/*
	gutter - fluid margin - 						body content 12 cols  					- fluid margin - gutter
	1			2							3												9												-3							-2		-1
	|			| 						|		|		|		|		|		|		|		|		|		|		|		|		|								|			|

	Nomenclature : 
	grid item default is 3 / -3 desktop
	grid item pass --gdspan as var if no preset
*/
.grid-row {
  --gdspan: 2/-2;
  display: grid;
  grid-template-columns: 1rem repeat(4, minmax(1rem, 1fr)) 1rem;
}
.grid-row .grid-data {
  grid-column: 2/-2;
}
.grid-row .grid-data[data-cols=edges] {
  grid-column: 1/-1;
}
.grid-row .grid-data[data-cols=boxed-8], .grid-row .grid-data[data-cols=boxed-10] {
  grid-column: 2/-2;
}

.grid-overlay {
  display: grid;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  grid-template-columns: 1rem minmax(1rem, 1fr) repeat(12, minmax(1rem, var(--col-width))) minmax(1rem, 1fr) 1rem;
  z-index: 100;
}
.grid-overlay .item {
  height: 100%;
}
.grid-overlay .item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.grid-overlay .item:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.3);
}
.grid-overlay .item.gutter {
  background-color: rgba(255, 0, 0, 0.4);
}
.grid-overlay .item.margin {
  background-color: rgba(0, 128, 0, 0.4);
}

/* --- 768 --- */
/* Specific 12 cols body layout to preserve left/right content under 1024 */
@media screen and (min-width: 48em) {
  .grid-row {
    grid-template-columns: 1rem 0.1rem 0.1rem repeat(10, minmax(1rem, var(--col-width))) 0.1rem 0.1rem 1rem;
    --gdspan: 3/-3;
  }
  .grid-row .grid-data {
    grid-column: var(--gdspan);
  }
  .grid-row.vcenter {
    align-items: center;
  }
}
/* --- 1024 --- */
@media screen and (min-width: 64em) {
  .grid-row {
    grid-template-columns: 1rem minmax(1rem, 1fr) repeat(12, minmax(1rem, var(--col-width))) minmax(1rem, 1fr) 1rem;
    --gdspan: 3/-3;
  }
  .grid-row .grid-data {
    grid-column: var(--gdspan);
  }
  .grid-row .grid-data[data-cols=boxed-8] {
    grid-column: 5/-5;
  }
  .grid-row .grid-data[data-cols=boxed-10] {
    grid-column: 4/-4;
  }
  .grid-row .grid-data[data-cols=title] {
    grid-column: 6/-6;
  }
  .grid-row .grid-data[data-cols=full] {
    grid-column: 2/-2;
  }
}
/**
 * ==================== @ THEMES
*/
.them-base {
  --theme-color: #6DAC88;
  --theme-color-alt: #e2eee7;
  --theme-color-shape: #99c5ac;
  --theme-color-nav: #2B4536;
  --theme-color-nav-alt: #44835E;
  --theme-rgb: 109, 172, 136;
}

.them-boat {
  --theme-color: #4C94B2;
  --theme-color-alt: #70a9c1;
  --theme-color-shape: #c9dfe8;
  --theme-color-nav: #1E3B47;
  --theme-color-nav-alt: #4C94B2;
  --theme-rgb: 76, 148, 178;
}

.them-campus {
  --theme-color: #F0E7CD;
  --theme-color-alt: #CCAE5B;
  --theme-color-shape: #E5D5AA;
  --theme-color-nav: #524624;
  --theme-color-nav-alt: #8C712B;
  --theme-rgb: 140, 113, 43;
}

.them-about {
  --theme-color: #EEEAE1;
  --theme-color-alt: #C7B99D;
  --theme-color-shape: #E2DBCC;
  --theme-color-nav: #504A40;
  --theme-color-nav-alt: #7D7464;
  --theme-rgb: 238, 234, 225;
}

.them-joinus {
  --theme-color: #2F6D48;
  --theme-color-alt: #2F6D48;
  --theme-color-nav: #2F6D48;
  --theme-color-nav-alt: #3B7E9B;
  --theme-rgb: 240, 231, 205;
}

.them-default {
  --theme-color: #fff;
  --theme-color-alt: #fff;
}

h2, h3, h4, h5 {
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  line-height: 1.1;
  margin: 0.5rem 0 1rem 0;
}

h1 {
  font-size: 1.875rem;
  line-height: 1.1;
  letter-spacing: normal;
  margin: 0.5rem 0;
}
h1.m--404 {
  font-size: 2.75rem;
}
h1.fontsize--2 {
  font-size: 2.5rem;
}
h1.fontsize--1 {
  font-size: 2.625rem;
}
h1.fontsize-1 {
  font-size: 2.875rem;
}
h1.fontsize-2 {
  font-size: 3rem;
}

h2 {
  font-size: 1.75rem;
  line-height: 1;
  position: relative;
}
h2.h1-like {
  font-size: 1.875rem;
}
h2.h3-like {
  font-size: 1.3125rem;
}

h3 {
  font-size: 1.3125rem;
  margin: 0.5rem 0;
}

h4 {
  font-size: 1rem;
}

p {
  margin: 0.5rem 0;
}

.entry, .entry p, p {
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.4;
}

.caption, .caption p {
  font-size: emrem(14);
}

.subtitle, .subtitle p {
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.entry a, .entry p a, p a {
  color: inherit;
  position: relative;
  word-break: break-word;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #524624;
          text-decoration-color: #524624;
  text-underline-offset: 2px;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  text-decoration-thickness: 1.4px;
}

a[href^="mailto:"] {
  word-break: break-all;
}

.grid-item ul {
  margin-left: 0;
  padding-left: 1rem;
}

.table-cookies {
  margin: 1rem 0;
}
.table-cookies td, .table-cookies th {
  border-bottom: 1px solid #eee;
  font-size: 0.9375rem;
  padding: 6px;
  text-align: left;
}
.table-cookies th {
  font-weight: 500;
}

@media all and (min-width: 48em) {
  h1 {
    font-size: 3.5rem;
    margin: 0.75rem 0;
  }
  h1.m--404 {
    font-size: 5rem;
  }
  h1.fontsize--2 {
    font-size: 4.75rem;
  }
  h1.fontsize--1 {
    font-size: 4.875rem;
  }
  h1.fontsize-1 {
    font-size: 5.125rem;
  }
  h1.fontsize-2 {
    font-size: 5.25rem;
  }
  h2 {
    font-size: 3.25rem;
  }
  h2.h1-like {
    font-size: 2.75rem;
  }
  h2.h3-like {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  .entry, .entry p, p {
    font-size: 1.125rem;
  }
  .caption, .caption p {
    font-size: 1rem;
  }
  .subtitle, .subtitle p {
    font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
  }
}
/**
 * ==================== @ BREADCRUMB
*/
.breadcrumb {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.breadcrumb__list {
  list-style: none;
  padding: 0;
}
.breadcrumb__list li {
  display: inline-block;
  font-size: 1em;
  margin-right: 0.75rem;
}
.breadcrumb__list li:not(:first-child)::before {
  content: "•";
  font-size: 2em;
  line-height: 0.5;
  margin-right: 6px;
  vertical-align: -7px;
}
.breadcrumb__list li span, .breadcrumb__list li a {
  font-size: 0.8125rem;
  font-weight: 300;
}
.breadcrumb__list li span.current, .breadcrumb__list li a.current {
  font-weight: 500;
}

/**
 * ==================== @ BUTTONS
 */
.btn-wrapper {
  padding: 1rem 0;
}
.btn-wrapper.m--center {
  text-align: center;
}
.btn-wrapper.m--right {
  text-align: right;
}

.btns-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.btn {
  background-color: #000;
  border-radius: 99px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.1;
  padding: 0.75rem 1rem;
}
.btn--go {
  border-radius: 50%;
  justify-content: center;
  height: 36px;
  width: 36px;
  padding: 0;
}
.btn--go .svg-icon {
  font-size: 0.875rem !important;
}
.btn--alt {
  background-color: #fff;
  color: #000;
}
.btn--frame {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
}
.btn--download .svg-icon {
  font-size: 1.5rem;
}
.btn .svg-icon {
  margin-top: 2px;
  transition: transform 200ms;
}
.btn:hover .svg-icon {
  transform: translateX(3px);
}
.btn:hover .download {
  transform: translateY(3px);
}
.card:hover .btn .svg-icon {
  transform: translateX(3px);
}
.btn-play {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #3B7E9B;
}
.btn-play .svg-icon {
  color: #fff;
  font-size: 1rem;
  margin-left: 2px;
}
.card:hover .btn-play {
  background-color: rgba(0, 0, 0, 0.2);
}

/**
 * ==================== @ PAGINATION - NEWS
*/
.pagination {
  margin: 2rem 0;
  width: 100%;
}
.pagination .pagination-list {
  display: flex;
  width: max-content;
  margin: 0 auto;
}
.pagination .pagination-list .page-items {
  margin: 0 0.3rem;
  text-align: center;
}
.pagination .pagination-list .page-items a {
  border-radius: 50%;
  color: #524624;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  transition: border-color 150ms;
}
.pagination .pagination-list .page-items .svg-icon {
  vertical-align: -2px;
}
.pagination .pagination-list .page-items.m--active a, .pagination .pagination-list .page-items:hover a {
  border: 1px solid #524624;
}

/**
 * ==================== @ TAGS
 */
.tag {
  background-color: #fff;
  border-radius: 99px;
  color: #000;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.2;
  margin: 0;
  padding: 5px 12px;
  transition: background-color 200ms;
}
.tag.metas__tags, .tag.card-news__tag, .listing-news .tag {
  background-color: rgb(var(--theme-rgb, "#000"));
  color: #fff;
}
.card-ressource .tag {
  background-color: transparent;
  border: 1px solid #000;
}

/**
 * ==================== @ GENERIC FORMS
*/
.form {
  font-family: "neue", Helvetica, Arial, sans-serif;
  position: relative;
}
.form__field {
  display: flex;
  position: relative;
  padding-bottom: 0.5rem;
}
.form__label {
  display: inline-block;
  font-size: 1.0625rem;
  font-weight: 300;
  padding-left: 16px;
  padding-bottom: 4px;
}
.form__label.m--mandatory span {
  cursor: pointer;
  font-size: 0.6875rem;
  font-weight: 300;
  line-height: 0.9;
}
.form__input, .form__text {
  border: 0;
  border-radius: 24px;
  color: #000;
  outline: 0;
  padding: 7px 16px;
  background-color: rgba(199, 185, 157, 0.2);
  font-size: 1.0625em;
  font-family: inherit;
  font-weight: 300;
}
.m--submit-inline .form__input, .m--submit-inline .form__text {
  padding-right: 25%;
  height: 41px;
  width: 100%;
}
.form__text {
  min-height: 120px;
  padding: 8px 16px;
  width: 100%;
}
.form__button {
  font-family: inherit;
  font-size: 0.9375em;
  font-weight: 500;
}
.m--submit-inline .form__button {
  position: absolute;
  right: 0;
  z-index: 2;
}
.form__button[disabled] {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
}
.form__message {
  background-color: rgba(244, 241, 235, 0.96);
  display: none;
  padding-top: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.form__message.active {
  display: block;
}
.form__message span {
  font-size: 1.125rem;
}
.form__message span.success {
  color: green;
}
.form__message span.success::before {
  content: "✓";
  height: 14px;
  width: 14px;
  margin-right: 0.5rem;
  display: inline-block;
}
.form__message span.failed {
  color: orange;
}
.form .f_error {
  color: red;
  padding-left: 1rem;
}
.form .f_alert {
  font-size: 1.125rem;
}
.form .f_alert.success {
  color: green;
  padding: 1rem;
}
.form .f_alert.success::before {
  content: "✓";
  height: 14px;
  width: 14px;
  margin-right: 0.5rem;
  display: inline-block;
}

/* Custom design Select
---------------------*/
select {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #504A40;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.125rem;
  padding: 0 0.5em;
  width: 100%;
}
select.filter-select {
  padding: 1.5rem 2rem 1.5rem 0.5rem;
}
.m--rounded select {
  background: #7D7464;
  color: #504A40;
  border-radius: 99px;
  overflow: hidden;
  padding: 1.5rem 2rem 1.5rem 1rem;
}
.m--rounded select:focus {
  background: #504A40;
  color: #fff;
}

select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  display: flex;
  max-width: 100%;
  border-radius: 0;
}
.select select:focus {
  outline: 1px solid #fff;
  outline-offset: -1px;
}
.select::after {
  content: "▼";
  color: #fff;
  font-size: 1rem;
  position: absolute;
  top: 35%;
  right: 1rem;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.select.m--rounded::after {
  color: #504A40;
}
.select.focused::after {
  color: #fff;
}
.select.m--light select {
  background: rgba(80, 74, 64, 0.1);
  color: #504A40;
  padding: 1em;
}
.select.m--light::after {
  color: #504A40;
}
.select.m--bluepale select {
  background: #7D7464;
  color: #504A40;
}
.select.m--bluepale::after {
  color: #504A40;
}

/* End custom select
---------------------*/
/* Some adjusts
---------------------*/
.form-contact .select::after {
  margin-right: 1rem;
}
.form-contact select {
  font-size: 0.8888888889em;
  padding: 1em;
}
.form-contact textarea.form-input {
  min-height: 180px;
}

@media screen and (min-width: 40em) {
  .form-field {
    /* Search header
    ----------------*/
  }
  .form-field.m--search {
    width: 200px;
  }
}
@media screen and (min-width: 64em) {
  .select {
    font-size: 1.375em;
  }
}
/**
 * ==================== @ FILTERS BARS & SELECTS
 */
.filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem 1rem;
}
.filter-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.filter-tag {
  background-color: rgba(var(--theme-rgb, "black"), 0.2);
  border-radius: 99px;
  color: #000;
  display: inline-flex;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  padding: 7px 16px;
  transition: background-color 300ms;
}
.filter-tag.them-all {
  background-color: #000;
  color: #fff;
}
.filter-tag:hover, .filter-tag.active {
  background-color: rgb(var(--theme-rgb, "#000"));
  color: #fff;
}
.filter-tag:hover.them-all, .filter-tag.active.them-all {
  background-color: #000;
}

/**
 * Audio player
*/
.audioplayer {
  cursor: pointer;
  position: relative;
  --ap-theme: #3B7E9B;
}

/**
 * ==================== @ HEADER
 */
.header {
  background-color: #000;
  padding-top: 14px;
  padding-bottom: 14px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__lang {
  display: none;
}
.header__menu-burger {
  border-radius: 50%;
  border: 1px solid #fff;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__menu .svg-icon {
  color: #fff;
}

@media all and (max-width: 1023px) {
  .header__nav {
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    position: fixed;
    left: 0;
    top: 64px;
    bottom: 0;
    width: 100vw;
    transform: translateX(-100%);
    transition: transform 350ms 50ms;
  }
  .is-nav-open .header__nav {
    transform: translateX(0);
  }
}
@media all and (min-width: 40em) {
  .header-accessibility {
    padding-right: 1.5rem;
  }
}
@media all and (min-width: 64em) {
  .header {
    min-height: 65px;
  }
  .header__menu {
    display: none;
  }
  .glightbox-open .header {
    margin-right: 17px;
  }
  .is-glossy .header {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
}
@media all and (min-width: 80em) {
  .header__lang {
    display: block;
  }
}
/**
 * ==================== @ FOOTER
 */
.footer {
  margin: 1.5rem 0;
}
.footer .padded-edges {
  border-top: 1px solid rgba(112, 112, 112, 0.1);
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer__logo img {
  margin: auto;
}
.footer__nav {
  display: flex;
}
.footer__nav-rs {
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.footer__nav-legal {
  flex-direction: column;
  gap: 0.5rem;
}
.footer__nav-legal ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.footer__nav-legal .wave {
  font-size: 1.75rem;
}

@media screen and (min-width: 64em) {
  .footer__nav {
    justify-content: space-between;
    align-items: baseline;
  }
  .footer__nav-rs {
    justify-content: flex-end;
  }
  .footer__nav-legal {
    flex-direction: row;
  }
  .footer__nav-legal ul {
    flex-direction: row;
  }
}
/**
 * ==================== @ MENU NAV
 */
.nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.nav ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.nav ul li > ul .nav__sublink {
  color: #fff;
}
.nav--main {
  height: 100%;
  padding: 1rem 2rem;
  max-width: 360px;
  margin: auto;
}
.nav--main ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.nav--main ul > li {
  position: relative;
}
.nav--footer {
  text-align: center;
}
.nav--main .nav__link {
  background-color: var(--theme-color-nav);
  border-radius: 99px;
  color: #fff;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 6px 12px;
  text-align: center;
  white-space: nowrap;
  transition: background-color 250ms;
}
.nav--main .nav__link .svg-icon {
  color: #fff;
  font-size: 1rem;
  margin-right: 6px;
}
.nav--main .nav__link:hover, .nav--main .nav__link[aria-current=page] {
  background-color: var(--theme-color-nav-alt);
}
.nav--footer .nav__link {
  font-size: 0.9375rem;
  font-weight: 500;
  white-space: nowrap;
}
.nav span.nav__link {
  font-weight: 300;
}
.nav span.nav__link .svg-icon {
  vertical-align: -8px;
}
.nav--joinus {
  margin-top: auto;
}

@media all and (max-width: 1023px) {
  .nav--main .nav__link {
    background-color: var(--theme-color-nav-alt);
    padding: 12px;
  }
  .nav ul li > ul {
    gap: 0.5rem;
    align-items: center;
    margin-top: 1.25rem;
  }
}
@media all and (min-width: 64em) {
  .nav {
    flex-direction: row;
    padding: 0;
  }
  .nav--main {
    padding: 0;
    max-width: none;
    margin: auto;
  }
  .nav ul {
    flex-direction: row;
    gap: 0.5rem;
  }
  .nav ul li:hover > ul {
    height: max-content;
    opacity: 1;
  }
  .nav ul li > ul {
    background-color: black;
    flex-direction: column;
    gap: 0.25rem;
    padding-top: 1rem;
    position: absolute;
    opacity: 0;
    min-width: 100%;
    height: 0;
    overflow: hidden;
    transition: opacity 250ms;
  }
  .nav ul li > ul .nav__sublink {
    font-size: 0.875rem;
    display: block;
    padding: 0.25rem 1rem;
  }
  .nav ul li > ul .nav__sublink:hover {
    background-color: #fff;
    color: black;
  }
  .nav--joinus .nav__link {
    display: none;
  }
}
@media all and (min-width: 80em) {
  .nav--joinus {
    margin-left: 2rem;
  }
  .nav--joinus .nav__link {
    display: inherit;
  }
}
/**
 * ==================== @ BLOCS
*/
.block-hero {
  margin-top: -9rem;
}
.block-hero__metas {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}
.block-title {
  padding-bottom: 0;
}
.block-suptitle {
  color: #C7B99D;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
}
.block-subtitle {
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}
.block-gallery {
  background-color: var(--theme-color);
  padding-top: 3rem;
  padding-bottom: 4rem;
  margin-bottom: -3rem;
}
.block-gallery .grid-cards {
  gap: 1rem;
}
.block-scrollbar {
  height: calc(100% - 48px);
  max-height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

/* --- 768 --- */
@media all and (min-width: 48em) {
  .layout-2cols .data-first, .layout-img-txt .data-first, .layout-txt-img .data-first, .layout-img-quote .data-first, .layout-quote-img .data-first {
    padding-right: 0.5rem;
  }
  .layout-2cols .data-last, .layout-img-txt .data-last, .layout-txt-img .data-last, .layout-img-quote .data-last, .layout-quote-img .data-last {
    padding-left: 0.5rem;
  }
}
/* --- 1024 --- */
@media all and (min-width: 64em) {
  .block-hero {
    margin-top: -9rem;
  }
  .block-suptitle {
    font-size: 1rem;
  }
  .block-title {
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
  .layout-2cols .data-first, .layout-img-txt .data-first, .layout-txt-img .data-first, .layout-img-quote .data-first, .layout-quote-img .data-first {
    padding-right: 2rem;
  }
  .layout-2cols .data-last, .layout-img-txt .data-last, .layout-txt-img .data-last, .layout-img-quote .data-last, .layout-quote-img .data-last {
    padding-left: 2rem;
  }
}
/**
 * ==================== @ BLOCS
*/
.block-accordion .tabs {
  overflow: hidden;
}
.block-accordion .tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}
.block-accordion .tab-label {
  display: block;
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  cursor: pointer;
  padding: 1rem 3rem 1rem 1rem;
  position: relative;
  transition: color 0.35s;
}
.block-accordion .tab-label::before, .block-accordion .tab-label::after {
  content: "";
  height: 2px;
  width: 18px;
  background-color: #504A40;
  position: absolute;
  top: 36px;
  right: 0;
  transition: transform 550ms;
}
.block-accordion .tab-label::after {
  transform: rotate(-90deg);
}
.block-accordion .tab-content {
  background-color: var(--beige);
  font-size: 1rem;
  max-height: 0;
  padding: 0 1rem;
  transition: all 0.35s;
}
.block-accordion .tab-content p {
  margin-bottom: 0;
}
.block-accordion .tab.active .tab-label::after {
  transform: rotate(180deg);
}
.block-accordion .tab.active .tab-content {
  max-height: 75vh;
  padding: 1rem;
}

/* === 1024px === */
@media screen and (min-width: 64em) {
  .block-accordion .tab.active .tab-content {
    max-height: 36vh;
    padding: 1rem;
  }
}
/**
 * ==================== @ BLOCS
*/
.block-boatsheet {
  background-image: linear-gradient(rgba(76, 148, 178, 0.2), rgba(76, 148, 178, 0.2));
  background-repeat: no-repeat;
  background-position: 0 200%;
  background-size: 100% 90%;
  margin-bottom: 0;
}
.block-boatsheet .data-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.block-boatsheet__title {
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 3.25rem;
}
.block-boatsheet__visual {
  max-width: 40vw;
}
.block-boatsheet__values-list {
  column-count: 2;
  column-gap: 4rem;
}
.block-boatsheet__values-list li {
  margin-bottom: 0.5rem;
}
.block-boatsheet__values-list label {
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
}
.block-boatsheet__values-list span {
  display: block;
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 1.375rem;
}

/* --- 1280 --- */
@media all and (min-width: 80em) {
  .block-boatsheet {
    background-position: 0 70%;
    background-size: 100% 70%;
    margin-bottom: 3rem;
  }
  .block-boatsheet .data-block {
    flex-direction: row;
  }
  .block-boatsheet__values-list li {
    margin-bottom: 1rem;
  }
  .block-boatsheet__values-list label {
    font-size: 1.0625rem;
  }
  .block-boatsheet__values-list span {
    font-size: 1.75rem;
  }
}
/**
 * ==================== @ BLOCS
*/
.block-mediabox {
  margin-top: 2rem;
  content-visibility: auto;
}
.block-mediabox .dta-first {
  margin-bottom: 0.5rem;
}
.block-mediabox__text {
  background-color: var(--theme-color);
  border-radius: var(--radius-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
.block-mediabox .video-cover--has-button {
  position: relative;
}
.block-mediabox .video-cover--has-button::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.block-mediabox .video-cover--has-button .btn-play-video {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -48px;
  transition: transform 250ms;
  z-index: 10;
}
.block-mediabox .video-cover--has-button .btn-play-video .svg-icon {
  color: #fff;
  font-size: 6rem;
}
.block-mediabox .video-cover--has-button.playing .btn-play-video {
  display: none;
}
.block-mediabox .video-cover--has-button.playing::before {
  content: none;
}

@media all and (min-width: 48em) {
  .block-mediabox .data-first {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .block-mediabox .data-last {
    margin-left: 0.5rem;
    padding-right: 0;
  }
  .block-mediabox__text {
    padding: 1rem;
  }
  .block-mediabox__text h2 {
    font-size: 1.75rem;
  }
}
@media all and (min-width: 64em) {
  .block-mediabox__text {
    padding: 2rem;
  }
  .block-mediabox__text h2 {
    font-size: 3.25rem;
  }
  .block-mediabox .video-cover--has-button .btn-play-video:hover {
    transform: scale3d(1.1, 1.1, 1);
  }
}
/**
 * ==================== @ BLOC LIST PODCASTS
*/
.block-podcasts {
  --scrollheight: 100vw;
}
.block-podcasts__visual {
  order: -1;
}
.block-podcasts__list {
  background-color: #c9dfe8;
  padding: 1rem;
  height: var(--scrollheight);
}

@media all and (min-width: 48em) {
  .block-podcasts__items {
    padding-right: 0.75rem;
  }
  .block-podcasts__visual {
    padding-left: 0.75rem;
  }
  .block-podcasts__visual img {
    height: var(--scrollheight);
    object-fit: cover;
  }
  .block-podcasts__list {
    height: var(--scrollheight);
    padding: 2rem;
    position: relative;
  }
}
@media all and (min-width: 48em) {
  .block-podcasts__visual {
    order: 2;
  }
}
@media all and (min-width: 48em) {
  .block-podcasts {
    --scrollheight: 33vw;
  }
}
/**
 * ==================== @ BLOCS
*/
.block-quotes {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}
.block-quotes .svg-icon {
  font-size: 3rem;
  position: absolute;
  color: var(--theme-color);
}
.block-quotes .svg-icon.quote-open {
  top: 0;
  left: 0;
}
.block-quotes .svg-icon.quote-close {
  bottom: 0;
  right: 0;
}
.block-quotes h2 {
  font-size: 2rem;
  text-align: center;
}
.block-quotes h2 span {
  display: block;
  font-size: 1rem;
  margin-top: 0.75rem;
}
.block-quotes .entry h3, .block-quotes .entry h4 {
  font-size: 1.375rem;
}
.block-quotes .entry p {
  font-size: 1.25rem;
}

@media all and (min-width: 48em) {
  .block-quotes {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
  .block-quotes .svg-icon.quote-open {
    left: 1.5rem;
  }
  .block-quotes h2 {
    font-size: 3rem;
    padding: 0 1rem;
  }
  .block-quotes h2 span {
    font-size: 1.1875rem;
  }
}
/**
 * ==================== @ BLOC LAYOUT RESSOURCE
*/
.block-ressource {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  counter-reset: cnt;
}
.block-ressource.m--head {
  background-color: #000;
  border-radius: var(--radius-8);
  color: #fff;
  margin-top: -4rem;
  padding: 1rem;
  z-index: 2;
}
.block-ressource > div {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding: 0.5rem;
}
.block-ressource > div label {
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
}
.block-ressource__documents {
  padding: 1rem;
}
.block-ressource__steps {
  order: -1;
}

@media all and (min-width: 48em) {
  .block-ressource {
    gap: 0;
    margin-top: -2rem;
    position: relative;
  }
  .block-ressource.m--head {
    display: flex;
    flex-direction: row;
  }
  .block-ressource.m--head > div {
    flex: 0 0 50%;
  }
  .block-ressource > div {
    padding: 1rem 2rem;
  }
  .block-ressource--edito {
    margin-top: 0;
    margin-bottom: 3rem;
    padding-top: 0;
    padding-bottom: 1rem;
  }
  .block-ressource--edito .data-last {
    padding-left: 0;
  }
}
/**
 * ==================== @ BLOC LIST VIDEOS YT
*/
.block-videos {
  --scrollheight: 56vw;
}
.block-videos__media .video-wrapper {
  background-color: #000;
  height: var(--scrollheight);
  overflow: hidden;
}
.block-videos__media .video-wrapper iframe {
  height: 100%;
  width: 100%;
}
.block-videos__media .video-wrapper .video-embed {
  display: none;
}
.block-videos__media .video-wrapper.playing figure {
  display: none;
}
.block-videos__media .video-wrapper.playing .video-embed {
  display: block;
}
.block-videos__list {
  background-color: #4C94B2;
  height: var(--scrollheight);
  padding: 1rem;
}

@media all and (min-width: 48em) {
  .block-videos {
    --scrollheight: 31vw;
  }
  .block-videos__items {
    padding-left: 0.75rem;
  }
  .block-videos__media {
    padding-right: 0.75rem;
  }
  .block-videos__list {
    padding: 2rem;
  }
}
/**
 * ==================== @ ELEMENT CARD / TEASER
*/
.card {
  display: block;
  position: relative;
}
.card-key {
  display: flex;
  column-gap: 1rem;
}
.card-key__icon {
  display: flex;
  flex-direction: column;
  flex: 0 0 36%;
}
.card-key__icon .svg-icon {
  font-size: 4rem;
}

/* --- 768 --- */
@media all and (min-width: 48em) {
  .card-key {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
  }
  .card-key__icon {
    flex: 1;
  }
  .card-key__icon .svg-icon {
    font-size: 7rem;
    height: auto;
  }
}
/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/
.card-audio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 0.75rem;
  padding: 0.5rem;
}
.card-audio__img {
  flex: 0 0 25%;
  min-width: 100px;
  order: 1;
}
.card-audio__img img {
  display: block;
}
.card-audio__desc {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  order: -1;
}
.card-audio__desc p {
  font-weight: 500;
}
.card-audio__desc div {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}
.card-audio__desc div p {
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  margin-top: 0;
}
.card-audio__desc div .svg-icon {
  color: #3B7E9B;
  font-size: 1.5rem;
}
.card-audio__length {
  order: 2;
  text-align: right;
}
.card-audio__length span {
  color: #3B7E9B;
  font-size: 0.9375rem;
  font-weight: 300;
}
.card-audio__play {
  order: 3;
}
.card-audio.playing, .card-audio:hover {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.03);
}

@media all and (min-width: 48em) {
  .card-audio {
    gap: 0.75rem;
  }
  .card-audio__img {
    flex: 0 0 15%;
  }
  .card-audio__desc {
    order: 2;
    flex: 1;
  }
  .card-audio__desc div p {
    font-size: 1.25rem;
  }
}
/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/
.card-diapo {
  transition: background-color 250ms;
}
.card-diapo::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  transition: left 250ms;
}
.card-diapo .svg-icon {
  color: #fff;
  font-size: 8rem;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 350ms;
  z-index: 2;
}
.card-diapo:hover .svg-icon {
  opacity: 1;
}
.card-diapo:hover::before {
  left: 0;
}

@media all and (min-width: 48em) {
  .card-diapo__title {
    font-size: 1.25rem;
  }
}
/**
 * ==================== @ ELEMENT CARD
 * Thèmes piliers.
*/
.card-mainstay {
  display: flex;
  flex-direction: column;
  position: relative;
}
.card-mainstay__icon {
  width: max-content;
}
.card-mainstay__icon .svg-icon {
  font-size: 2rem;
  transition: transform 300ms;
}
.card-mainstay h2 {
  font-size: 1rem;
  text-transform: uppercase;
  position: relative;
}
.card-mainstay h2::after {
  content: "";
  background-color: #000;
  width: 24px;
  height: 3px;
  position: absolute;
  bottom: -4px;
  left: 0;
}
.card-mainstay .entry {
  font-size: 0.9375rem;
  font-weight: 500;
}
.card-mainstay .btn-wrapper {
  margin-top: -1rem;
  text-align: right;
}
.card-mainstay::before {
  content: url(/assets/images/layout/shape_blur1.webp);
  position: absolute;
  top: -80%;
  left: -30%;
  z-index: -1;
}
.card-mainstay:nth-of-type(2)::before {
  content: url(/assets/images/layout/shape_blur2.webp);
  left: -15%;
  top: -50%;
}
.card-mainstay:nth-of-type(3)::before {
  content: url(/assets/images/layout/shape_blur3.webp);
  left: 0;
}
.card-mainstay:hover .card-mainstay__icon .svg-icon {
  transform: rotate(5deg);
  transform-origin: bottom center;
}

@media all and (min-width: 48em) {
  .card-mainstay h2 {
    font-size: 1.25rem;
  }
  .card-mainstay .entry {
    font-size: 1.0625rem;
  }
  .card-mainstay .btn-wrapper {
    margin-top: auto;
    text-align: left;
  }
}
/**
 * ==================== @ ELEMENT CARD EXPLORER
*/
.card-member {
  padding: 1rem;
}
.card-member figure {
  overflow: visible;
}
.card-member figure picture:nth-of-type(1) {
  position: relative;
  transition: opacity 300ms;
  z-index: 2;
}
.card-member figure picture:nth-of-type(2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  filter: blur(2px);
  transform: rotate(-3deg);
  transition: transform 200ms, filter 200ms;
}
.card-member__name {
  color: #fff;
  font-size: 1.0625rem;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1rem;
  z-index: 3;
}
.card-member__name span {
  display: block;
  font-size: 0.9375rem;
  margin-top: 4px;
}
.card-member:hover picture:nth-of-type(1) {
  opacity: 0;
}
.card-member:hover picture:nth-of-type(2) {
  transform: rotate(0);
  filter: none;
}

/**
 * ==================== @ ELEMENT CARD MOOC
*/
.card-mooc {
  background-color: #CCAE5B;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: var(--radius-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  min-height: 15vw;
}
.card-mooc .svg-icon {
  color: #fff !important;
  font-size: 3.5rem;
}
.card-mooc__title {
  font-size: 1.25rem;
}

/**
 * ==================== @ ELEMENT CARD FOR RELATED NEWS
*/
.card-news {
  background-color: transparent;
  border-radius: var(--radius-16);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  position: relative;
  transition: background-color 350ms;
}
.listing-news .card-news, .them-base .card-news, .them-boat .card-news, .them-campus .card-news {
  color: #000;
}
.card-news__metas {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.card-news__date {
  font-size: 0.75rem;
  font-weight: 300;
  margin-left: auto;
}
.card-news__img {
  overflow: hidden;
}
.card-news__img img {
  transition: filter 200ms;
}
.card-news__caption {
  color: inherit;
  font-family: "neue", Helvetica, Arial, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  margin: 0.5rem;
}
.card-news__link {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.card-news:hover {
  background-color: #fff;
  color: #000;
}
.card-news:hover .card-news__link {
  display: inline-flex;
}
.card-news:hover .card-news__tag {
  background-color: rgb(var(--theme-rgb, "#000"));
}
.card-news:hover img {
  filter: blur(4px);
}

/**
 * ==================== @ ELEMENT CARD RESSOURCE
*/
.card-ressource {
  border-radius: var(--radius-8);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  min-height: 15vw;
}
.card-ressource__img {
  background-color: #CCAE5B;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
.card-ressource__img picture {
  opacity: 0.1;
  height: 100%;
}
.card-ressource__img picture img {
  object-fit: cover;
  height: 100%;
}
.card-ressource > div, .card-ressource > h3 {
  position: relative;
}
.card-ressource__type {
  font-size: 0.8125rem;
  font-weight: 300;
}
.card-ressource__topic {
  font-size: 1.625rem;
  font-family: "neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}
.card-ressource__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
}
.card-ressource__link {
  margin-left: auto;
}
.card-ressource-step {
  padding: 2rem;
  border: 1px solid rgba(112, 112, 112, 0.1);
  margin-bottom: 1rem;
  padding-left: 5rem;
  counter-increment: cnt;
}
.card-ressource-step::before {
  content: counter(cnt);
  background-color: #8C712B;
  border-radius: 50%;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding-bottom: 4px;
  position: absolute;
  top: 2rem;
  left: 1rem;
}
.card-ressource-step p {
  margin-bottom: 1.5rem;
}
.card-ressource-step > div {
  display: flex;
  flex-direction: column;
}
.card-ressource-step > div label {
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
}
.card-ressource-step > div span {
  font-weight: 300;
}

@media all and (min-width: 48em) {
  .card-ressource-step {
    padding-left: 8rem;
  }
  .card-ressource-step::before {
    font-size: 2.25rem;
    width: 64px;
    height: 64px;
    left: 2rem;
  }
}
/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/
.card-partner {
  display: flex;
  gap: 1rem;
}
.card-partner figure {
  flex: 0 0 33%;
}
.card-partner__metas {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
}
.card-partner__metas .svg-icon {
  color: rgba(109, 172, 136, 0.3);
  font-size: 3rem;
  position: absolute;
  left: -1.5rem;
  top: -0.5rem;
  z-index: 2;
}
.card-partner__metas h2 {
  font-size: 1.75rem;
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
}
.card-partner__metas h2 small {
  display: block;
  margin-top: 4px;
}
.card-partner__metas p {
  font-size: 1rem;
}

.card-coop h3 {
  text-align: center;
}
.card-coop picture {
  will-change: transform;
  transition: transform 500ms ease-out;
}

@media all and (min-width: 48em) {
  .card-coop:hover picture {
    transform: scale3d(1.1, 1.1, 1);
  }
}
/**
 * ==================== @ ELEMENT CARD STYLED
*/
.card-styled figure {
  position: relative;
  overflow: hidden;
}
.card-styled figure::after {
  content: "";
  background-color: var(--theme-color-alt, "white");
  border-radius: 16px 16px 0 0;
  height: 40px;
  position: absolute;
  left: -2px;
  right: -2px;
  bottom: -6px;
  transform: rotate(4deg);
  transform-origin: 0 100%;
  outline: 1px solid transparent;
  -webkit-transform-style: preserve-3d;
}
.card-styled picture {
  will-change: transform;
  transition: transform 500ms ease-out;
}
.card-styled__title {
  position: relative;
  margin-top: -0.5rem;
  padding: 0 0.5rem;
}
.card-styled__caption {
  padding: 0 0.5rem;
}
.card-styled__caption p {
  font-size: 1rem;
  font-weight: 500;
}

@media all and (min-width: 48em) {
  .card-styled:hover picture {
    transform: scale3d(1.1, 1.1, 1);
  }
}
/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/
.card-video {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  transition: background-color 250ms;
}
.card-video__icn, .card-video__play {
  flex: 0 0 25px;
}
.card-video__play {
  align-self: last baseline;
  text-align: center;
}
.card-video__metas {
  color: #000;
  flex: 1;
  transition: color 250ms;
}
.card-video__title {
  color: inherit;
  font-family: "clearfaceStd", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  word-wrap: break-word;
  word-break: break-word;
}
.card-video:hover, .card-video.playing {
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.03);
}
.card-video:hover .card-video__metas, .card-video.playing .card-video__metas {
  color: #fff;
}

@media all and (min-width: 48em) {
  .card-video__title {
    font-size: 1.25rem;
  }
}
/**
 * ==================== @ GRID of CARDS
*/
.grid-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  margin-top: 2rem;
}
.grid-cards .card {
  grid-column: span 3;
}
.grid-cards[data-items="4"] {
  grid-template-columns: repeat(4, 1fr);
}
.grid-cards[data-items="4"] .card {
  grid-column: span 4;
}
.grid-cards--keys {
  margin-top: 0;
}

@media all and (min-width: 48em) {
  .grid-cards[data-gap="4"] {
    grid-gap: 4rem;
  }
  .grid-cards .card, .grid-cards[data-items="4"] .card {
    grid-column: auto;
  }
}
/**
 * ==================== @ LAYOUT FORM
*/
.grid-form {
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
}
.grid-form__field .form__input {
  width: 100%;
}

@media all and (min-width: 48em) {
  .grid-form {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem 2rem;
  }
  .grid-form__field.m--fullwidth {
    grid-column: 1/-1;
  }
}
/**
 * ==================== @ GRID 3 / 4 SLIDES
*/
.grid-logos {
  display: grid;
  align-items: center;
}
.grid-logos .logo-item {
  position: relative;
  text-align: center;
}
.grid-logos .logo-item .logo {
  display: inline-block;
}
.grid-logos .logo-item::before {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  width: 1px;
  height: 2rem;
  top: 50%;
  margin-top: -1rem;
}

@media all and (max-width: 1151px) {
  .grid-logos {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-logos .logo-item {
    padding: 1rem;
  }
  .grid-logos .logo-item:not(:nth-child(3n+1)):not(:first-child)::before {
    content: "";
  }
}
@media all and (min-width: 72em) {
  .grid-logos {
    grid-template-columns: repeat(5, 1fr);
  }
  .grid-logos .logo-item {
    padding: 1rem 2rem;
  }
  .grid-logos .logo-item:not(:nth-child(5n+1)):not(:first-child)::before {
    content: "";
  }
}
/**
 * ==================== @ GRID 3 / 4 SLIDES
*/
.grid-moocs {
  background-color: #CCAE5B;
  border-radius: var(--radius-8);
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 1rem;
}

/* === 1024px === */
@media screen and (min-width: 64em) {
  .grid-moocs {
    grid-template-columns: repeat(2, 1fr);
  }
}
/**
 * ==================== @ GRID 3 / 4 SLIDES
*/
.grid-slides {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 1rem;
  padding-top: 2rem;
}
.grid-slides > .card {
  flex: 0 0 100%;
}

@media all and (min-width: 48em) {
  .grid-slides {
    justify-content: center;
  }
  .grid-slides > .card {
    flex: 0 1 33%;
    max-width: calc(33% - 1rem);
  }
  .grid-slides[data-items="4"] > .card {
    flex: 0 1 25%;
    max-width: calc(25% - 1rem);
  }
}
/**
 * ==================== @ FOOTER - NEWSLETTER
*/
.newsletter {
  margin-top: 2rem;
  content-visibility: auto;
}
.newsletter picture img {
  width: 100%;
}
.newsletter .grid-row:nth-of-type(2) {
  margin-top: -8%;
  position: relative;
}
.newsletter__bloc {
  background-color: #F4F1EB;
  border-radius: var(--radius-16);
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
}
.newsletter__bloc-joinus {
  text-align: center;
}

@media all and (min-width: 64em) {
  .newsletter__bloc {
    padding: 1rem 3rem;
  }
  .newsletter__bloc-form {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
  .newsletter__bloc-joinus {
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
}
/*
 * ==================== @ SWIPER CUSTOMIZE
 */
.swiper {
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-color: #C7B99D;
  --swiper-pagination-bullet-inactive-opacity: .4;
  --swiper-pagination-bullet-border-radius: 0;
  --swiper-pagination-bullet-width: 24px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-inactive-color: #C7B99D;
  --swiper-navigation-color: #C7B99D;
  max-height: 72vh;
  opacity: 0;
  padding-bottom: 3rem !important;
  width: 100%;
}
.swiper-initialized {
  opacity: 1;
}
.swiper-nav {
  display: none !important;
  background-color: #C7B99D;
  border-radius: 50% !important;
  height: 36px !important;
  width: 36px !important;
  top: auto !important;
  bottom: 0 !important;
  right: 0 !important;
  left: auto !important;
}
.swiper-nav::after {
  content: none !important;
}
.swiper-nav.swiper-button-prev {
  right: 40px !important;
}
.swiper-nav .svg-icon {
  color: #000;
  font-size: 0.75rem !important;
  height: 1rem;
}

@media screen and (min-width: 40em) {
  .swiper {
    --swiper-pagination-bullet-width: 48px;
  }
  .swiper-nav {
    display: flex !important;
  }
}
.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  backface-visibility: hidden;
  outline: 0;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  transition: transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom, .glightbox-container .ginner-container.desc-top {
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left, .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe, .gslide video {
  outline: 0 !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: 0;
  border: none;
  -webkit-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  object-fit: cover;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-bottom .gslide-image img, .desc-top .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img, .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: grabbing !important;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
  display: none;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
  margin: auto;
}

.gslide-video::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
  margin: auto;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: grabbing !important;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  box-shadow: none !important;
}

.desc-bottom .gslide-media, .desc-top .gslide-media {
  margin: 0 auto;
  flex-direction: column;
}

.gslide-description {
  position: relative;
  flex: 1 0 100%;
}

.gslide-description.description-left, .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom, .gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.75) 100%);
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: 700;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gclose, .gnext, .gprev {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gclose svg, .gnext svg, .gprev svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: 0.1;
}

.gclose .garrow, .gnext .garrow, .gprev .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gclose, .glightbox-closing .gnext, .glightbox-closing .gprev {
  opacity: 0 !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: 400;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gclose path, .glightbox-clean .gnext path, .glightbox-clean .gprev path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}

.gfadeIn {
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  animation: gzoomOut 0.5s ease;
}
@keyframes lightboxLoader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes gslideInLeft {
  from {
    opacity: 0;
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    transform: translate3d(60%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}
@keyframes gzoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image, .glightbox-container .ginner-container.desc-top .gslide-image img {
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left, .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner, .glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}
@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}
@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}
/**
 * ==================== @ GENERIC / PAGES
 */
body:not(.home) .page-header {
  align-items: last baseline;
  padding-bottom: 1rem;
  position: relative;
  overflow: hidden;
}
body:not(.home) .page-header.has-hero {
  padding-bottom: 10rem;
}
body:not(.home) .page-header__shape {
  background-color: var(--theme-color);
  padding-left: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: right;
  z-index: -1;
}
body:not(.home) .page-header__shape #shape-header path {
  fill: var(--theme-color-shape);
}
body:not(.home) .page-header h2.h3-like {
  text-transform: uppercase;
}
body:not(.home) .page-header__title {
  font-weight: 700 !important;
  text-transform: uppercase;
  -moz-osx-font-smoothing: grayscale;
}
body:not(.home) .page-header__subtitle {
  font-size: 1.0625rem;
  font-weight: 500;
}
body:not(.home) .page-header__metas {
  display: flex;
  align-items: center;
  gap: 2rem 1rem;
}
body:not(.home) .page-header__metas .metas__date {
  font-size: 0.75rem;
  margin-left: auto;
}

.page-summary {
  background-color: #fff;
  margin-top: 0;
  position: sticky;
  top: 65px;
  z-index: 10;
}
.page-summary .grid-data {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
  justify-content: center;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.page-summary .grid-data a {
  display: block;
  font-weight: 500;
}
.page-summary .grid-data a::after {
  content: "";
  background-image: url('data:image/svg+xml,<svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>');
  display: inline-block;
  margin-bottom: -7px;
  height: 24px;
  width: 24px;
}
.page-summary.subpages a::after {
  transform: rotate(-90deg);
}

/**
 * ==================== @ HOME
 */
.home .page-header {
  position: relative;
}
.home .page-header figure img {
  object-fit: cover;
  height: calc(100vh - 60px);
}
.home .page-header .caption {
  position: absolute;
  left: 1rem;
  top: 58%;
  transform: translateY(-50%);
  z-index: 2;
}
.home .page-header .caption h1 {
  color: #fff;
  font-family: "neue", Helvetica, Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.home .page-header .caption h1.fontsize--4 {
  font-size: 1.5rem;
}
.home .page-header .caption h1.fontsize--3 {
  font-size: 1.5rem;
}
.home .page-header .caption h1.fontsize--2 {
  font-size: 1.875rem;
}
.home .page-header .caption h1.fontsize--1 {
  font-size: 2.1875rem;
}
.home .page-header .caption h1.fontsize-1 {
  font-size: 2.8125rem;
}
.home .page-header .caption h1.fontsize-2 {
  font-size: 3.125rem;
}
.home .l-main-content {
  margin-top: 0;
}

/**
 * ==================== @ ALL PAGES
 */
/* === 640px === */
@media screen and (min-width: 40em) {
  .home .page-header .caption {
    left: 2rem;
  }
  .home .page-header .caption h1 {
    font-size: 3rem;
  }
}
/* === 1024px === */
@media screen and (min-width: 64em) {
  .home .page-header .caption {
    left: 4rem;
  }
  .home .page-header .caption h1 {
    font-size: 3.5rem;
  }
  body:not(.home) .page-header__shape {
    padding-left: 0;
  }
  body:not(.home) .page-header .page-header__title {
    font-size: 2.875em;
    padding-right: 3.5rem;
  }
  body.ressource .page-header {
    padding-bottom: 4rem;
  }
}
/* === 1280px === */
@media screen and (min-width: 80em) {
  .home .page-header .caption {
    left: 4rem;
  }
  .home .page-header .caption h1 {
    font-size: 6rem;
  }
  .home .page-header .caption h1.fontsize--4 {
    font-size: 4rem;
  }
  .home .page-header .caption h1.fontsize--3 {
    font-size: 4.5rem;
  }
  .home .page-header .caption h1.fontsize--2 {
    font-size: 5rem;
  }
  .home .page-header .caption h1.fontsize--1 {
    font-size: 5.5rem;
  }
  .home .page-header .caption h1.fontsize-1 {
    font-size: 6.5rem;
  }
  .home .page-header .caption h1.fontsize-2 {
    font-size: 7rem;
  }
}
/**
 * ==================== @ BLOCKS SECTION
 */
section {
  margin-top: 1.5rem;
  position: relative;
}
section[data-wave=true]::after {
  content: url(/assets/images/layout/waves.svg);
  position: absolute;
  left: -0.5rem;
  right: -2rem;
  bottom: -66%;
  z-index: -1;
  max-width: calc(100% + 1rem);
  overflow: hidden;
}
section[data-wave-pos=auto]::after {
  bottom: auto;
}
section[data-wave-pos=mid]::after {
  bottom: -50%;
}
section[data-wave-pos=bottom]::after {
  bottom: -100%;
}
section [data-cols=title] h2 {
  font-size: 1.875rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
section.layout-img-quote, section.layout-quote-img {
  align-items: center;
}
section.home-themes {
  margin-bottom: 3rem;
}
section.block-explorers {
  background-color: #E9E5D9;
  padding: 4rem 0;
}
section.block-explorations {
  background-color: var(--theme-color-alt);
  padding: 4rem 0;
}
section.related-news {
  background-color: var(--theme-color-alt);
  content-visibility: auto;
  padding: 2rem 0;
}
section.related-news h2 {
  color: #000;
}
section.related-news.them-boat {
  background-color: rgba(76, 148, 178, 0.2);
  color: #000;
}
section.related-news.them-boat .svg-icon.explore {
  color: #000;
}
section.related-news.them-base .svg-icon.explore {
  color: #000;
}
section.related-news.them-campus {
  background-color: rgba(204, 174, 91, 0.1);
}
section.related-news.them-campus .svg-icon.explore {
  color: #000;
}
section.related-news.them-campus {
  background-color: rgba(204, 174, 91, 0.1);
}
section.related-news.them-campus .svg-icon.explore {
  color: #000;
}
section.block-moocs {
  padding: 2rem 0;
}
section.block-moocs .block-moocs__caption {
  background-color: rgba(204, 174, 91, 0.1);
  border-radius: var(--radius-8);
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
}
section.listing-news {
  background-color: #F8F7F3;
  content-visibility: auto;
  padding: 2rem 0;
}
section.contact-form {
  background-color: #F8F7F3;
  padding-top: 1rem;
}
section.contact-form form {
  padding: 2rem 0;
}

.l-main-content section:first-child {
  margin-top: 0;
}

/* === 1024px === */
@media screen and (min-width: 64em) {
  section {
    margin-top: 2rem;
  }
  section [data-cols=title] h2 {
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  section.padded {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  section[data-wave=true] {
    margin-bottom: 8rem;
  }
  section.home-themes {
    margin-bottom: 8rem;
  }
  section.block-explorers {
    padding: 4rem;
  }
  section.block-moocs .block-moocs__caption {
    margin-right: 1rem;
  }
  section.related-news {
    padding: 4rem 0;
  }
}
/**
 * ==================== @ RESSOURCES PAGE LIST & FILTER
 */
body.our-ressources .page-header {
  margin-bottom: 0;
}

.ressources {
  background-color: #F8F7F3;
  margin-top: 0;
  padding-bottom: 2rem;
}
.ressources-filter, .ressources-results {
  background-color: #fff;
  border-radius: 4px;
}
.ressources-filter {
  margin-top: -7rem;
  padding: 2rem 1rem;
  height: calc(100% + 7rem);
}
.ressources-filter__items {
  margin-bottom: 1rem;
}
.ressources-filter__items label {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 300;
}
.ressources-filter__label {
  font-size: 1.125rem;
  font-weight: 500;
}
.ressources-results {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  padding: 2rem;
}

/**
 * ==================== @ RESSOURCE PAGE
 */
body.ressource .page-header {
  margin-bottom: 0;
  padding-bottom: 6rem;
}
body.ressource .ressource {
  background-color: #F8F7F3;
  margin-top: 0;
  padding-bottom: 2rem;
}

@media all and (min-width: 48em) {
  .ressources-results {
    margin-top: -7rem;
    margin-left: 1rem;
    padding: 2rem;
  }
}
@media all and (min-width: 64em) {
  .ressources-filter {
    margin-right: 1rem;
  }
  .ressources-results {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 2rem;
    margin-top: -7rem;
    margin-left: 1rem;
    padding: 2rem;
  }
}
/**
 * ==================== @ Really ?!
 */
.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.is-hidden {
  display: none !important;
  overflow: hidden !important;
}