/**
 * ==================== @ GENERIC FLOW TOOLS
 */

.l-clearfix { @extend %clearfix; }

/*.l-wrapper {
	letter-spacing: .1px;
	padding-left: 1rem;
	padding-right: 1rem;
	max-width: 80em;
	margin: 0 auto;
  position: relative;
	z-index: 1;
}*/

.l-unlist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-untitle {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.l-center {
	text-align: center;
}

.l-right {
	text-align: right;
}

.py-1-0 {
	padding-top: 1rem;
}

.py-2-0 {
	padding-top: 2rem;
}

.py-3-0 {
	padding-top: 3rem;
}

.py-4-0 {
	padding-top: 4rem;
}

.my-2-0 {
	margin-top: 2rem;
}

.my-3-0 {
	margin-top: 3rem;
}

.py-0-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.my-0-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.img-block {
	display: block;
}

/* radius layout
------------------------*/
.rounded {
	border-radius: var(--radius-16);

	&-6 {
		border-radius: var(--radius-6);
	}

	&-8 {
		border-radius: var(--radius-8);
	}
}


/* EMBED
----------------------------------*/
.l-embed {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16.9 */
  height: 0;
	overflow: hidden;

  // &.m--rXXX {} // Ratio modifier
}

.l-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
	min-height: 400px;
}

.l-main {
	margin-top: 45px;

	&-content {
		overflow: hidden;
    margin-top: 1rem;

    .them-campus & {
      margin-top: 2rem;
    }
	}
}

#contact-form {
	scroll-margin-top: 150px;	
}

.l-scrollbar {
  position: relative;
}

/* Backgrounds
------------------------*/
.bg-grey {
	background-color: $color7;
	color: $color2;
	p, h2, h3 {
		color: $color2;
	}
}

.c-green {
	color: $color4;
}
	

@media screen and (max-width: 767px) {

	.l--desktop {
		display: none;
	}
}


@media screen and (min-width: $bp-l) {

  .l--mobile {
    display: none;
  }

	.padded-edges {
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.l-main {
		margin-top: 65px;

    &-content {
      margin-top: 2rem;
    }
	}
	
	/* radius layout
	------------------------*/
		
	.half-rounded {
		border-radius: 0 var(--radius-24) 0 var(--radius-24);
	}
}