/**
 * ==================== @ ELEMENT CARD EXPLORER
*/

.card-member {
  padding: 1rem;

  figure {
    overflow: visible;

    picture:nth-of-type(1) {
      position: relative;
      transition: opacity 300ms;
      z-index: 2;
    }

    picture:nth-of-type(2) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      filter: blur(2px);
      transform: rotate(-3deg);
      transition: transform 200ms, filter 200ms;
    }
  }

  &__name {
    color: #fff;
    font-size: rem(17);
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 1rem;
    z-index: 3;

    span {
      display: block;
      font-size: rem(15);
      margin-top: 4px;
    }
  }

  &:hover {
    picture:nth-of-type(1) {
      opacity: 0;
    }
    picture:nth-of-type(2) {
      transform: rotate(0);
      filter: none;
    }
  }
}


@media all and (min-width : $bp-m) {

}
