/**
 * ==================== @ BLOCS
*/

.block {

  &-hero {
    margin-top: -9rem;

    &__metas {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: .5rem;
    }
  }

  &-title {
    padding-bottom: 0;
  }

  &-suptitle {
    color: $color13;
    font-size: rem(14);
    font-weight: 500;
    text-transform: uppercase;
  }

  &-subtitle {
    font-family: $font-alt;
    font-size: rem(18);
    margin-bottom: 1.5rem;
  }

  &-gallery {
    background-color: var(--theme-color);
    padding-top: 3rem;
    padding-bottom: 4rem;
    margin-bottom: -3rem;

    .grid-cards {
      gap: 1rem;
    }
  }
  
  &-scrollbar {
    height: calc(100% - 48px);
    max-height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
  }
}

/* --- 768 --- */
@media all and (min-width : $bp-m) {

  .layout {
  
    &-2cols, &-img-txt, &-txt-img, &-img-quote, &-quote-img {
  
      .data-first {
        padding-right: .5rem;
      }
  
      .data-last {
        padding-left: .5rem;
      }
    }
  }
}

/* --- 1024 --- */
@media all and (min-width : $bp-l) {

  .block {

    &-hero {
      margin-top: -9rem;
    }

    &-suptitle {
      font-size: rem(16);
    }

    &-title {
      margin-top: 2rem;
      padding-bottom: 1rem;
    }
  }

  .layout {
  
    &-2cols, &-img-txt, &-txt-img, &-img-quote, &-quote-img {
  
      .data-first {
        padding-right: 2rem;
      }
  
      .data-last {
        padding-left: 2rem;
      }
    }
  }
}
