/**
 * ==================== @ BLOCS
*/

.block-quotes {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;

  .svg-icon {
    font-size: rem(48);
    position: absolute;
    color: var(--theme-color);

    &.quote-open  {
      top: 0;
      left: 0;
    }

    &.quote-close {
      bottom: 0;
      right: 0;
    }
  }

  h2 {
    font-size: rem(32);
    text-align: center;

    span {
      display: block;
      font-size: rem(16);
      margin-top: .75rem;
    }
  }

  .entry {

    h3, h4 {
      font-size: rem(22);
    }

    p {
      font-size: rem(20);
    }
  }
}

@media all and (min-width : $bp-m) {
  .block-quotes {
    padding-top: 1.5rem;
    padding-bottom: 1rem;

    .svg-icon {

      &.quote-open  {
        left: 1.5rem;
      }
    }

    h2 {
      font-size: rem(48);
      padding: 0 1rem;

      span {
        font-size: rem(19);
      }
    }
  }

}
