/**
 * ==================== @ BLOCS
*/

.block-boatsheet {

  background-image: linear-gradient(rgba($color11, .2),rgba($color11, .2));
  background-repeat: no-repeat;
  background-position: 0 200%;
  background-size: 100% 90%;
  margin-bottom: 0;

  .data-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  &__title {
    font-family: $font-alt;
    font-size: rem(52);
  }

  &__visual {
    max-width: 40vw;
  }

  &__values {

  }

  &__values-list {
    column-count: 2;
    column-gap: 4rem;

    li {
      margin-bottom: .5rem;
    }

    label {
      display: block;
      font-size: rem(14);
      font-weight: 300;
    }

    span {
      display: block;
      font-family: $font-alt;
      font-size: rem(22);
    }
  }
}

/* --- 1280 --- */
@media all and (min-width : $bp-1280) {

  .block-boatsheet {
    background-position: 0 70%;
    background-size: 100% 70%;
    margin-bottom: 3rem;

    .data-block {
      flex-direction: row;
    }

    &__values-list {
      li {
        margin-bottom: 1rem;
      }
  
      label {
        font-size: rem(17);
      }
  
      span {
        font-size: rem(28);
      }
    }
  }
}
