/**
 * ==================== @ BLOC LIST PODCASTS
*/

.block-podcasts {
  --scrollheight: 100vw;

  &__items {
    
  }

  &__visual {
    order: -1;
  }

  &__list {
    background-color: $color20;
    padding: 1rem;
    height: var(--scrollheight);
  }
}

@media all and (min-width : $bp-m) {

  .block-podcasts {

    &__items {
      padding-right: .75rem;
    }
  
    &__visual {
      padding-left: .75rem;

      img {
        height: var(--scrollheight);
        object-fit: cover;
      }
    }

    &__list {
      height: var(--scrollheight);
      padding: 2rem;
      position: relative;
    }
  }
}

@media all and (min-width : $bp-m) {
  .block-podcasts {

    &__visual {
      order: 2;
    }
  }
}

@media all and (min-width : $bp-m) {
  .block-podcasts {
    --scrollheight: 33vw;
  }
}
