/**
 * ==================== @ BUTTONS
 */

.btn-wrapper {
	padding: 1rem 0;

	&.m--center {
		text-align: center;
	}
	
	&.m--right {
		text-align: right;
	}
}

.btns-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.btn {
	background-color: #000;
	border-radius: 99px;
	color: #fff;
	display: inline-flex;
	align-items: center;
	gap: 8px;
	font-size: rem(14);
	font-weight: 500;
	line-height: 1.1;
	padding: .75rem 1rem;

	&--go {
		border-radius: 50%;
		justify-content: center;
		height: 36px;
		width: 36px;
		padding: 0;

		.svg-icon {
			font-size: rem(14) !important;
		}
	}

	&--alt {
		background-color: #fff;
		color: #000;
	}

	&--frame {
		background-color: transparent;
		border: 1px solid #000;
		color: #000;
	}

	&--download {
		.svg-icon {
			font-size: rem(24);
		}
	}

	.svg-icon {
		margin-top: 2px;
		transition: transform 200ms;
	}

	&:hover .svg-icon {
		transform: translateX(3px);
	}

	&:hover .download {
		transform: translateY(3px);
	}

	.card:hover & {
		.svg-icon {
			transform: translateX(3px);
		}
	}

	&-play {
		border-radius: 50%;
		height: 32px;
		width: 32px;
		background-color: $color9;

		.svg-icon {
			color: #fff;
			font-size: rem(16);
			margin-left: 2px;
		}

		.card:hover & {
			background-color: rgba(0,0,0,.2);
		}
	}
}

@media all and (min-width : $bp-l) {
}