/**
 * ==================== @ BREADCRUMB
*/


.breadcrumb {
	padding-top: 2rem;
	padding-bottom: 1rem;
}

.breadcrumb__list {
	list-style: none;
	padding: 0;

	li {
		display: inline-block;
		font-size: em(16);
		margin-right: .75rem;
		
		&:not(:first-child)::before {
			content: '\2022';
			font-size: em(32);
      line-height: .5;
			margin-right: 6px;
			vertical-align: -7px;
		}
		
		span, a {
			font-size: rem(13);
			font-weight: 300;

			&.current {
				font-weight: 500;
			}
		}
	}
}