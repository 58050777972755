
h2, h3, h4, h5 {
	font-family: $font-alt;
	line-height: 1.1;
	margin: .5rem 0 1rem 0;
}

h1 {
	font-size: rem(30);
	line-height: 1.1;
  letter-spacing: normal;
	margin: .5rem 0;
		
	&.m--404 {
		font-size: rem(44);
	}

  &.fontsize--2{font-size: rem(40);}
  &.fontsize--1{font-size: rem(42);}
  &.fontsize-1{font-size: rem(46);}
  &.fontsize-2{font-size: rem(48);}
}

h2 {
	font-size: rem(28);
	line-height: 1;
	position: relative;
	
	&.h1-like {
		font-size: rem(30);
	}
	
	&.h3-like {
		font-size: rem(21);
	}
}

h3 {
	font-size: rem(21);
	margin: .5rem 0;
}

h4 {
	font-size: rem(16);
}

p {
	margin: .5rem 0;
}

.entry, .entry p, p {
	font-size: rem(15);
	font-weight: 300;
	line-height: 1.4;
}

.caption, .caption p {
	font-size: emrem(14);
}

.subtitle, .subtitle p {
	font-family: $font-alt;
	font-size: rem(16);
}

.entry, .entry p, p {
	
	a {
		color: inherit;
		position: relative;
		word-break: break-word;
		text-decoration-line: underline;
		text-decoration-color: $color1;
  	text-underline-offset: 2px;
		text-decoration-skip-ink: auto;
		text-decoration-thickness: 1.4px;
	}
}

a[href^="mailto:"] {
	word-break: break-all;
}

ul {

	.grid-item & {
		margin-left: 0;
		padding-left: 1rem;
	}
}

.table-cookies {
	margin: 1rem 0;

	td, th {
		border-bottom: 1px solid #eee;
		font-size: rem(15);
		padding: 6px;
		text-align: left;
	}

	th {
		font-weight: 500;
	}
}

@media all and (min-width : $bp-m) {

	h1 {
		font-size: rem(56);
		margin: .75rem 0;
		
		&.m--404 {
			font-size: rem(80);
		}

    &.fontsize--2{font-size: rem(76);}
    &.fontsize--1{font-size: rem(78);}
    &.fontsize-1{font-size: rem(82);}
    &.fontsize-2{font-size: rem(84);}
	}

	h2 {
		font-size: rem(52);
	
		&.h1-like {
			font-size: rem(44);
		}
	
		&.h3-like {
			font-size: rem(20);
		}
	}

	h3 {
		font-size: rem(28);
	}
	
	.entry, .entry p, p {
		font-size: rem(18);
	}

	.caption, .caption p {
		font-size: rem(16);
	}

	.subtitle, .subtitle p {
		font-family: $font-alt;
		font-size: rem(20);
	}
}