/**
 * ==================== @ GLOBAL LAYOUT
 */

/*
	gutter - fluid margin - 						body content 12 cols  					- fluid margin - gutter
	1			2							3												9												-3							-2		-1
	|			| 						|		|		|		|		|		|		|		|		|		|		|		|		|								|			|

	Nomenclature : 
	grid item default is 3 / -3 desktop
	grid item pass --gdspan as var if no preset
*/

.grid-row {
	--gdspan: 2/-2;
	display: grid;
	grid-template-columns: 1rem repeat(4, minmax(1rem, 1fr)) 1rem;

	.grid-data {
		grid-column: 2/-2;
		
		&[data-cols="edges"] {
			grid-column: 1/-1;
		}
		// Preset 8 cols
		&[data-cols="boxed-8"],
		&[data-cols="boxed-10"] {
			grid-column: 2/-2;
		}
	}
}

.grid-overlay {
	display: grid;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	grid-template-columns: 1rem minmax(1rem, 1fr) repeat(12, minmax(1rem, var(--col-width))) minmax(1rem, 1fr) 1rem;
	z-index: 100;

	.item {
		height: 100%;

		&:nth-of-type(odd) {
			background-color: rgba(0,0,0,.1);
		}
		&:nth-of-type(even) {
			background-color: rgba(0,0,0,.3);
		}

		&.gutter {
			background-color: rgba(red,.4);
		}

		&.margin {
			background-color: rgba(green,.4);
		}
	}
}

/* --- 768 --- */
/* Specific 12 cols body layout to preserve left/right content under 1024 */
@media screen and (min-width:$bp-m) {
	 
	.grid-row {
		grid-template-columns: 1rem .1rem .1rem repeat(10, minmax(1rem, var(--col-width))) .1rem .1rem 1rem;
		// Default body - 12 cols
		--gdspan: 3/-3;
		.grid-data {
			grid-column: var(--gdspan);
		}

    &.vcenter {
      align-items: center;
    }
	}
}

/* --- 1024 --- */
@media screen and (min-width:$bp-l) {
	 
	.grid-row {
		grid-template-columns: 1rem minmax(1rem, 1fr) repeat(12, minmax(1rem, var(--col-width))) minmax(1rem, 1fr) 1rem;
		// Default body - 12 cols
		--gdspan: 3/-3;
		.grid-data {
			grid-column: var(--gdspan);
			
			// Preset 8 cols
			&[data-cols="boxed-8"] {
				grid-column: 5/-5;
			}
			
			// Preset 10 cols -  Most used by edito
			&[data-cols="boxed-10"] {
				grid-column: 4/-4;
			}
			
			// Preset title
			&[data-cols="title"] {
				grid-column: 6/-6;
			}
			
			// Preset full width
			&[data-cols="full"] {
				grid-column: 2/-2;
			}
		}
	}
}