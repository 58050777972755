/**
 * ==================== @ ELEMENT CARD FOR RELATED NEWS
*/

.card-news {
  background-color: transparent;
  border-radius: var(--radius-16);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  position: relative;
  transition: background-color 350ms;
  $parent: &;

  .listing-news &, .them-base &, .them-boat &, .them-campus &  {
    color: #000;
  }

  &__metas {
    display: flex;
    gap: 1rem;
    margin-bottom: .5rem;
  }

  &__date {
    font-size: rem(12);
    font-weight: 300;
    margin-left: auto;
  }

  &__img {
    overflow: hidden;
    img {
      transition: filter 200ms;
    }
  }

  &__caption {
    color: inherit;
    font-family: $font-stack;
    font-size: rem(15);
    font-weight: 400;
    margin: .5rem;
  }

  &__link {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  &:hover {
    background-color: #fff;
    color: #000;

    #{$parent}__link {
      display: inline-flex;
    }

    #{$parent}__tag {
      background-color: rgb(var(--theme-rgb, '#000'));
    }

    img {
      filter: blur(4px);
    }
  }
}