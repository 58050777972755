/**
 * ==================== @ GRID of CARDS
*/

.grid-cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  margin-top: 2rem;

  .card {
    grid-column: span 3;
  }

  &[data-items="4"] {
    grid-template-columns: repeat(4, 1fr);
    .card {
      grid-column: span 4;
    }
  }

  &--keys {
    margin-top: 0;
  }
}

@media all and (min-width : $bp-m) {

  .grid-cards {

    &[data-gap="4"] {
      grid-gap: 4rem;
    }

    .card, &[data-items="4"] .card {
      grid-column: auto;
    }
  }
}
