/**
 * ==================== @ MENU NAV
 */
.nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  ul {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  ul li > ul {

    .nav__sublink {
      color: #fff;
    }
  }

  &--main {
    height: 100%;
    padding: 1rem 2rem;
    max-width: 360px;
    margin: auto;
  
    ul {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      > li {
        position: relative;
      }
    }
  }

  &--footer {
    text-align: center;
  }

  &__link {

    .nav--main & {
      background-color: var(--theme-color-nav);
      border-radius: 99px;
      color: #fff;
      display: block;
      font-size: rem(14);
      font-weight: 500;
      padding: 6px 12px;
      text-align: center;
      white-space: nowrap;
      transition: background-color 250ms;

      .svg-icon {
        color: #fff;
        font-size: rem(16);
        margin-right: 6px;
      }

      &:hover, &[aria-current="page"] {
        background-color: var(--theme-color-nav-alt);
      }
    }

    .nav--footer & {
      font-size: rem(15);
      font-weight: 500;
      white-space: nowrap;
    }
  }

  span.nav__link {
    font-weight: 300;

    .svg-icon {
      vertical-align: -8px;
    }
  }

  &--joinus {
    margin-top: auto;
  }
}

@media all and (max-width : 1023px) {

  .nav {
    
    &--main .nav__link {
      background-color: var(--theme-color-nav-alt);
      padding: 12px;
    }

    ul li > ul {
      gap: .5rem;
      align-items: center;
      margin-top: 1.25rem;
    }
  }
}

@media all and (min-width : $bp-l) {
  .nav {
    flex-direction: row;
    padding: 0;

    &--main {
      padding: 0;
      max-width: none;
      margin: auto;
    }
  
    ul {
      flex-direction: row;
      gap: .5rem;

      li:hover > ul {
        height: max-content;
        opacity: 1;
      }
    }

    ul li > ul {
      background-color: black;
      flex-direction: column;
      gap: .25rem;
      padding-top: 1rem;
      position: absolute;
      opacity: 0;
      min-width: 100%;
      height: 0;
      overflow: hidden;
      transition: opacity 250ms;

      .nav__sublink {
        font-size: rem(14);
        display: block;
        padding: .25rem 1rem;

        &:hover {
          background-color: #fff;
          color: black;
        }
      }
    }

    &--joinus {

      .nav__link {
        display: none;
      }
    }
  }
}

@media all and (min-width : $bp-1280) {
  .nav {
    &--joinus {
      margin-left: 2rem;

      .nav__link {
        display: inherit;
      }
    }
  }
}
