/**
 * ==================== @ FILTERS BARS & SELECTS
 */
 
.filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem 1rem;

  &-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &-tag {
    background-color:  rgba(var(--theme-rgb, 'black'), .2);
    border-radius: 99px;
    color: #000;
    display: inline-flex;
    align-items: center;
    font-size: rem(15);
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    padding: 7px 16px;
    transition: background-color 300ms;

    &.them-all {
      background-color: #000;
      color: #fff;
    }

    &:hover, &.active {
      background-color: rgb(var(--theme-rgb, '#000'));
      color: #fff;

      &.them-all {
        background-color: #000;
      }
    }
  }
}