/**
 * ==================== @ BLOCKS SECTION
 */

section  {
  margin-top: 1.5rem;
  position: relative;

  &[data-wave="true"] {

    &::after {
      content: url(/assets/images/layout/waves.svg);
      position: absolute;
      left: -.5rem;
      right: -2rem;
      bottom: -66%;
      z-index: -1;
      max-width: calc(100% + 1rem);
      overflow: hidden;
    }
  }

  &[data-wave-pos="auto"] {
    &::after {
      bottom: auto;
    }
  }

  &[data-wave-pos="mid"] {
    &::after {
      bottom: -50%;
    }
  }

  &[data-wave-pos="bottom"] {
    &::after {
      bottom: -100%;
    }
  }

  [data-cols="title"] {
    h2 {
      font-size: rem(30);
      margin-top: .75rem;
      margin-bottom: .75rem;
    }
  }

  &.layout {
    
    &-img-quote, &-quote-img {
      align-items: center;
    }
  }
  
  &.home-themes {
    margin-bottom: 3rem;
  }
  
  &.block-explorers {
    background-color: $c-sepia;
    padding: 4rem 0;
  }
  
  &.block-explorations {
    background-color: var(--theme-color-alt);
    padding: 4rem 0;
  }
  
  &.related-news {
    background-color: var(--theme-color-alt);
    content-visibility: auto;
    padding: 2rem 0;

    h2 {
      color: #000;
    }

    &.them-boat {
      background-color: rgba($color11, .2);
      color: #000;

      .svg-icon.explore {
        color: #000;
      }
    }

    &.them-base {

      .svg-icon.explore {
        color: #000;
      }
    }

    &.them-campus {
      background-color: rgba($color12, .1);

      .svg-icon.explore {
        color: #000;
      }
    }

    &.them-campus {
      background-color: rgba($color12, .1);

      .svg-icon.explore {
        color: #000;
      }
    }
  }

  &.block-moocs {
    padding: 2rem 0;
    
    .block-moocs__caption {
      background-color: rgba($color12, .1);
      border-radius: var(--radius-8);
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 2rem;
    }
  }
  
  &.listing-news {
    background-color: $bg-sand;
    content-visibility: auto;
    padding: 2rem 0;
  }
  
  &.contact-form {
    background-color: $bg-sand;
    padding-top: 1rem;

    form {
      padding: 2rem 0;
    }
  }
}

.l-main-content section:first-child {
  margin-top: 0;
}

/* === 1024px === */
@media screen and (min-width:$bp-l) {

  section  {
    margin-top: 2rem;

    [data-cols="title"] {
      h2 {
        font-size: rem(48);
        margin-top: 1rem;
        margin-bottom: .5rem;
      }
    }

    &.padded {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  
    &[data-wave="true"] {
     margin-bottom: 8rem;
    }

    &.home-themes {
      margin-bottom: 8rem;
    }
  
    &.block-explorers {
      padding: 4rem;
    }

    &.block-moocs {
      
      .block-moocs__caption {
        margin-right: 1rem;
      }
    }

    &.related-news {
      padding: 4rem 0;
    }
  }
}
