/**
 * ==================== @ GENERIC FORMS
*/

.form {
	font-family: $font-stack;
	position: relative;

	&__field {
		display: flex;
		position: relative;
		padding-bottom: .5rem;
	}

	&__label {
		display: inline-block;
		font-size: rem(17);
		font-weight: 300;
		padding-left: 16px;
		padding-bottom: 4px;
		
		&.m--mandatory {

			span {
				cursor: pointer;
				font-size: rem(11);
				font-weight: 300;
				line-height: .9;
			}
		}
	}

	&__input, &__text {
		border: 0;
		border-radius: 24px;
		color: #000;
		outline: 0;
		padding: 7px 16px;
		background-color: rgba($color13, .2);
		font-size: em(17);
		font-family: inherit;
		font-weight: 300;

		.m--submit-inline & {
			padding-right: 25%;
			height: 41px;
			width: 100%;
		}
	}

	&__text {
		min-height: 120px;
		padding: 8px 16px;
		width: 100%;
	}

	&__button {
		font-family: inherit;
		font-size: em(15);
		font-weight: 500;
		
		.m--submit-inline & {
			position: absolute;
			right: 0;
			z-index: 2;
		}

		&[disabled] {
			background-color: rgba(0,0,0,.5);
			cursor: default;
		}
	}

	&__message {
		background-color: rgba($color16, .96);
		display: none;
		padding-top: 2rem;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 10;

		&.active {
			display: block;
		}

		span {
			font-size: rem(18);

			&.success {
				color: green;

				&::before {
					content: "✓";
					height: 14px;
					width: 14px;
					margin-right: .5rem;
					display: inline-block;
				}
			}

			&.failed {
				color: orange;
			}
		}
	}

	.f_error {
		color: red;
		padding-left: 1rem;
	}

	.f_alert {
		font-size: rem(18);
		
		&.success {
			color: green;
			padding: 1rem;

			&::before {
				content: "✓";
				height: 14px;
				width: 14px;
				margin-right: .5rem;
				display: inline-block;
			}
		}
	}
}

/* Custom design Select
---------------------*/
select {
	appearance: none;
	outline: 0;
	box-shadow: none;
	border: 0 !important;
	background: $color2;
	background-image: none;
	color: #fff;
	cursor: pointer;
	font-family: inherit;
	font-size: rem(18);
	padding: 0 .5em;
	width: 100%;

	&.filter-select {
		padding: 1.5rem 2rem 1.5rem .5rem;
	}

	.m--rounded & {
		background: $color3;
		color: $color2;
		border-radius: 99px;
		overflow: hidden;
		padding: 1.5rem 2rem 1.5rem 1rem;

		&:focus {
			background: $color2;
			color: #fff;
		}
	}
}

select::-ms-expand {
	display: none;
}

.select {
	position: relative;
	display: flex;
	max-width: 100%;
	border-radius: 0;

	select {

		&:focus {
			outline: 1px solid #fff;
			outline-offset: -1px;
		}
	}
	
	&::after {
		content: '\25BC';
		color: #fff;
		font-size: rem(16);
		position: absolute;
		top: 35%;
		right: 1rem;
		cursor:pointer;
		pointer-events:none;
		transition:.25s all ease;
	}

	&.m--rounded::after {
		color: $color2;
	}

	&.focused::after {
		color: #fff;
	}

	&.m--light {

		select {
			background: rgba($bg-input, .1);
			color: $color2;
			padding: 1em;
		}

		&::after {
			color: $color2;
		}
	}

	&.m--bluepale {
		select {
			background: $color3;
			color: $color2;
		}

		&::after {
			color: $color2;
		}
	}
}

/* End custom select
---------------------*/



/* Some adjusts
---------------------*/
.form-contact {

	.select {
		&::after {
			margin-right: 1rem;
		}
	}

	select {
		font-size: em(16, 18);
		padding: 1em;
	}

	textarea.form-input {
		min-height: 180px;
	}
}

@media screen and (min-width:$bp-s) {
	.form-field {

		/* Search header
		----------------*/
		&.m--search {
			width: 200px;
		}
	}
}

@media screen and (min-width:$bp-l) {
	.select {
		font-size: em(22);
	}
}
