/*
 * ==================== @ SWIPER CUSTOMIZE
 */

 .swiper {
	--swiper-pagination-bottom: 8px;
	--swiper-pagination-color: #{$color13};
	--swiper-pagination-bullet-inactive-opacity: .4;
	--swiper-pagination-bullet-border-radius: 0;
	--swiper-pagination-bullet-width: 24px;
	--swiper-pagination-bullet-height: 4px;
	--swiper-pagination-bullet-inactive-color: #{$color13};
	--swiper-navigation-color: #{$color13};
	max-height: 72vh;
	opacity: 0;
	padding-bottom: 3rem !important;
	width: 100%;

	&-initialized {
		opacity: 1;
	}
	
	&-nav {
		display: none !important;;
		background-color: $color13;
		border-radius: 50% !important;
		height: 36px !important;
		width: 36px !important;
		top: auto !important;
		bottom: 0 !important;
		right: 0 !important;
		left: auto !important;

		&::after {
			content: none !important;
		}

		&.swiper-button-prev {
			right: 40px !important;
		}

		.svg-icon {
			color: #000;
			font-size: rem(12) !important;
			height: 1rem;
		}
	}
 }

 @media screen and (min-width:$bp-s) {
	.swiper {
		--swiper-pagination-bullet-width: 48px;

		&-nav {
			display: flex !important;
		}
	}
 }