/**
 * ==================== @ ELEMENT CARD / TEASER
*/

.card {
  display: block;
  position: relative;

  &-key {
    display: flex;
    column-gap: 1rem;

    &__icon {
      display: flex;
      flex-direction: column;
      flex: 0 0 36%;

      .svg-icon {
        font-size: rem(64);
      }
    }
  }
}

/* --- 768 --- */
@media all and (min-width : $bp-m) {
  .card {
  
    &-key {
      display: flex;
      flex-direction: column;
      
      column-gap: 1rem;
  
      &__icon {
        flex: 1;
  
        .svg-icon {
          font-size: rem(112);
          height: auto;
        }
      }
    }
  }
}
