/**
 * ==================== @ BLOCS
*/

.block-mediabox {
  margin-top: 2rem;
  content-visibility: auto;
  
  .dta-first {
    margin-bottom: .5rem;
  }
  
  &__text {
    background-color: var(--theme-color);
    border-radius: var(--radius-8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }

  .video-cover--has-button {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.90) 100%);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    .btn-play-video {
      position: absolute;
      left: 50%;
      top: 40%;
      margin-left: -48px;
      transition: transform 250ms;
      z-index: 10;

      .svg-icon {
        color: #fff;
        font-size: rem(96);
      }
    }

    &.playing {
      .btn-play-video {
        display: none;
      }

      &::before {
        content: none;
      }
    }
  }
}

@media all and (min-width : $bp-m) {

  .block-mediabox {

    .data-first {
      margin-right: .5rem;
      margin-bottom: 0;
    }

    .data-last {
      margin-left: .5rem;
      padding-right: 0;
    }
  
    &__text {
      padding: 1rem;
      
      h2 {
        font-size: rem(28);
      }
    }
  }
}

@media all and (min-width : $bp-l) {

  .block-mediabox {
  
    &__text {
      padding: 2rem;
      
      h2 {
        font-size: rem(52);
      }
    }

    .video-cover--has-button {

      .btn-play-video:hover {
        transform: scale3d(1.1,1.1,1);
      }
    }
  }
}
