/**
 * ==================== @ RESSOURCES PAGE LIST & FILTER
 */
body.our-ressources {
	.page-header {
		margin-bottom: 0;
	}
}

.ressources {
	background-color: $bg-sand;
	margin-top: 0;
	padding-bottom: 2rem;

	&-filter, &-results {
		background-color: #fff;
		border-radius: 4px;
	}

	&-filter {
		margin-top: -7rem;
		padding: 2rem 1rem;
		height: calc(100% + 7rem);

		&__items {
			margin-bottom: 1rem;

			label {
				cursor: pointer;
				font-size: rem(14);
				font-weight: 300;
			}

		}

		&__label {
			font-size: rem(18);
			font-weight: 500;
		}
	}

	&-results {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1rem;
		margin-top: 1rem;
		padding: 2rem;
	}
}

/**
 * ==================== @ RESSOURCE PAGE
 */
body.ressource {

	.page-header {
		margin-bottom: 0;
		padding-bottom: 6rem;
	}

	.ressource {
		background-color: $bg-sand;
		margin-top: 0;
		padding-bottom: 2rem;
	}
}

@media all and (min-width : $bp-m) {

	.ressources {

		&-results {
			margin-top: -7rem;
			margin-left: 1rem;
			padding: 2rem;
		}
	}
}

@media all and (min-width : $bp-l) {

	.ressources {

		&-filter {
			margin-right: 1rem;
		}

		&-results {
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem 2rem;
			margin-top: -7rem;
			margin-left: 1rem;
			padding: 2rem;
		}
	}
}
