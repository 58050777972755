/**
 * ==================== @ TAGS
 */
 
.tag {
  background-color: #fff;
  border-radius: 99px;
  color: #000;
  display: flex;;
  align-items: center;
  font-size: rem(12);
  font-weight: 300;
  line-height: 1.2;
  margin: 0;
  padding: 5px 12px;
  transition: background-color 200ms;

  &.metas__tags, &.card-news__tag, .listing-news & {
    background-color: rgb(var(--theme-rgb, '#000'));
    color: #fff;
  }

  &.them-campus {
   // color: #000;
  }

  .card-ressource & {
    background-color: transparent;
    border: 1px solid #000;
  }
}