/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/

.card-partner {
  display: flex;
  gap: 1rem;

  figure {
    flex: 0 0 33%;
  }

  &__metas {
    display: flex;
    flex-direction: column;
    padding-bottom: .5rem;
    padding-right: .5rem;
    position: relative;

    .svg-icon {
      color: rgba($color10, .3);
      font-size: rem(48);
      position: absolute;
      left: -1.5rem;
      top: -.5rem;
      z-index: 2;
    }

    h2 {
      font-size: rem(28);
      font-family: $font-alt;

      small {
        display: block;
        margin-top: 4px;
      }
    }

    p {
      font-size: rem(16);
    }
  }
}

.card-coop {

  h3 {
    text-align: center;
  }

  picture {
    will-change: transform;
    transition: transform 500ms ease-out;
  }
}

@media all and (min-width : $bp-m) {

  .card-coop {

    &:hover {

      picture {
        transform: scale3d(1.1,1.1,1);
      }
    }
  }
}