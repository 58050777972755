/**
 * ==================== @ GRID 3 / 4 SLIDES
*/

.grid-slides {
	display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 1rem;
  padding-top: 2rem;

  > .card {
    flex: 0 0 100%;
  }
}

@media all and (min-width : $bp-m) {

  .grid-slides {
    justify-content: center;

    > .card {
      flex: 0 1 33%;
      max-width: calc(33% - 1rem);
    }
  
    &[data-items="4"] {
  
      > .card {
        flex: 0 1 25%;
        max-width: calc(25% - 1rem);
      }
    }
  }
}
