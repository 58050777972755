/**
 * ==================== @ FOOTER - NEWSLETTER
*/

.newsletter {
  margin-top: 2rem;
  content-visibility: auto;

  picture img {
    width: 100%;
  }

  .grid-row:nth-of-type(2) {
    margin-top: -8%;
    position: relative;
  }

  &__bloc {
    background-color: $color16;
    border-radius: var(--radius-16);
    padding: 1rem;
    margin: 0 1rem 1rem 1rem;

    &-form {
    }

    &-joinus {
      text-align: center;
    }
  }
}

@media all and (min-width : $bp-l) {
	
	.newsletter {

    &__bloc {
      padding: 1rem 3rem;
  
      &-form {
        margin-left: 1rem;
        margin-right: .5rem;
      }
  
      &-joinus {
        margin-left: .5rem;
        margin-right: 1rem;
      }
    }
	}
}