/**
 * ==================== @ THEMES
*/


.them-base { // green
  --theme-color: #{$color10};
  --theme-color-alt: #{$color18};
  --theme-color-shape: #{$color17};
  --theme-color-nav: #{$color7};
  --theme-color-nav-alt: #{$color6};
  --theme-rgb: 109, 172, 136;
}

.them-boat { // blue
  --theme-color: #{$color11};
  --theme-color-alt: #{$color19};
  --theme-color-shape: #{$color20};
  --theme-color-nav: #{$color8};
  --theme-color-nav-alt: #{$color11};
  --theme-rgb: 76, 148, 178;
}

.them-campus { // ocre
  --theme-color: #{$color14};
  --theme-color-alt: #{$color12};
  --theme-color-shape: #{$color21};
  --theme-color-nav: #{$color1};
  --theme-color-nav-alt: #{$color4};
  --theme-rgb: 140, 113, 43;
}

.them-about {// sand
  --theme-color: #{$color15};
  --theme-color-alt: #{$color13};
  --theme-color-shape: #{$color22};
  --theme-color-nav: #{$color2};
  --theme-color-nav-alt: #{$color3};
  --theme-rgb: 238, 234, 225;
}

.them-joinus {
  --theme-color: #{$color5};
  --theme-color-alt: #{$color5};
  --theme-color-nav: #{$color5};
  --theme-color-nav-alt: #{$color9};
  --theme-rgb: 240, 231, 205;
}

.them-default {
  --theme-color: #fff;
  --theme-color-alt: #fff;
}
