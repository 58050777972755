/**
 * ==================== @ ELEMENT CARD MOOC
*/

.card-mooc {
  background-color: $color12;
  box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.03);
  border-radius: var(--radius-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  min-height: 15vw;

  .svg-icon {
    color: #fff !important;
    font-size: rem(56);
  }

  &__title {
    font-size: rem(20);
  }
}