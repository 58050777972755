/**
 * ==================== @ GRID 3 / 4 SLIDES
*/

.grid-logos {
	display: grid;
  align-items: center;

  .logo-item {
    position: relative;
    text-align: center;

    .logo {
      display: inline-block;
    }

    &::before {
      background-color: rgba(0,0,0,.1);
      position: absolute;
      left: 0;
      width: 1px;
      height: 2rem;
      top: 50%;
      margin-top: -1rem;
    }
  }
}

@media all and (max-width : 1151px) {

  .grid-logos {
    grid-template-columns: repeat(3, 1fr);

    .logo-item {
      padding: 1rem;

      &:not(:nth-child(3n+1)):not(:first-child)::before {
        content: '';
      }
    }
  }
}

@media all and (min-width : $bp-lx) {

  .grid-logos {
    grid-template-columns: repeat(5, 1fr);

    .logo-item {
      padding: 1rem 2rem;

      &:not(:nth-child(5n+1)):not(:first-child)::before {
        content: '';
      }
    }
  }
}
