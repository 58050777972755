/**
 * ==================== @ ELEMENT CARD AS SLIDE
*/

.card-diapo {
  transition: background-color 250ms;
  $parent: &;

  &::before {
    content: '';
    background-color: rgba(0,0,0,.6);
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    transition: left 250ms;
  }

  .svg-icon {
    color: #fff;
    font-size: 8rem;
    opacity: 0;
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: 350ms;
    z-index: 2;
  }

  &:hover {
    .svg-icon {
      opacity: 1;
    }

    &::before {
      left: 0;
    }
  }

}

@media all and (min-width : $bp-m) {

  .card-diapo {

    &__title {
      font-size: rem(20);
    }
  }
}