/**
 * ==================== @ ELEMENT CARD RESSOURCE
*/

.card-ressource {
  border-radius: var(--radius-8);
  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 1rem;
  min-height: 15vw;

  &__img {
    background-color: $color12;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    picture {
      opacity: .1;
      height: 100%;

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  > div, > h3 {
    position: relative;
  }
  
  &__type {
    font-size: rem(13);
    font-weight: 300;
  }
  
  &__topic {
    font-size: rem(26);
    font-family: $font-stack;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-top: auto;
  }

  &__link {
    margin-left: auto;
  }

  &-step {
    padding: 2rem;
    border: 1px solid rgba(112,112,112,.1);
    margin-bottom: 1rem;
    padding-left: 5rem;
    counter-increment: cnt;

    &::before {
      content: counter(cnt);
      background-color: $color4;
      border-radius: 50%;
      color: #fff;
      font-size: rem(18);
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      padding-bottom: 4px;
      position: absolute;
      top: 2rem;
      left: 1rem;
    }

    p {
      margin-bottom: 1.5rem;
    }

    > div {
      display: flex;
      flex-direction: column;

      label {
        font-size: rem(13);
        font-weight: 500;
        text-transform: uppercase;
      }

      span {
        font-weight: 300;
      }
    }
  }
}

@media all and (min-width : $bp-m) {

  .card-ressource {

    &-step {
      padding-left: 8rem;

      &::before {
        font-size: rem(36);
        width: 64px;
        height: 64px;
        left: 2rem;
      }
    }
  }
}