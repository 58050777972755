/**
 * ==================== @ ELEMENT CARD
 * Thèmes piliers.
*/

.card-mainstay {
  display: flex;
  flex-direction: column;
  position: relative;
  $parent: &;

  &__icon {
    width: max-content;

    .svg-icon {
      font-size: rem(32);
      transition: transform 300ms;
    }
  }

  h2 {
    font-size: rem(16);
    text-transform: uppercase;
    position: relative;

    &::after {
      content: '';
      background-color: #000;
      width: 24px;
      height: 3px;
      position: absolute;
      bottom: -4px;
      left: 0;
    }
  }

  .entry {
    font-size: rem(15);
    font-weight: 500;
  }

  .btn-wrapper {
    margin-top: -1rem;
    text-align: right;
  }

  &::before {
    content: url(/assets/images/layout/shape_blur1.webp);
    position: absolute;
    top: -80%;
    left: -30%;
    z-index: -1;
  }

  &:nth-of-type(2)::before {
    content: url(/assets/images/layout/shape_blur2.webp);
    left: -15%;
    top: -50%;
  }

  &:nth-of-type(3)::before {
    content: url(/assets/images/layout/shape_blur3.webp);
    left: 0;
  }

  &:hover {
    #{$parent}__icon .svg-icon {
      transform: rotate(5deg);
      transform-origin: bottom center;
    }
  }
}

@media all and (min-width : $bp-m) {
  .card-mainstay {

    h2 {
      font-size: rem(20);
    }

    .entry {
      font-size: rem(17);
    }

    .btn-wrapper {
      margin-top: auto;
      text-align: left;
    }
  }
}
